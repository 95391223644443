import { Drawer, MantineNumberSize, Portal } from "@mantine/core";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type DrawerProps = {
  component: React.ReactNode;
  parameter: any;
  opened: boolean;
  title: string | React.ReactNode;
  onClose: (result?: any) => void;
  closeOnClickOutside?: boolean;
  size?: MantineNumberSize;
};

type DrawerContextType = {
  openDrawer: (
    component: React.ReactNode,
    parameter: any,
    title: string | React.ReactNode,
    closeOnClickOutside?: boolean,
    size?: MantineNumberSize
  ) => Promise<any>;
  closeDrawer: (result?: any) => void;
};

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

const TRANSITION_DURATION = 200;

const DrawerStackManager: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [drawerStack, setDrawerStack] = useState<DrawerProps[]>([]);
  const [openedStates, setOpenedStates] = useState<boolean[]>([]);

  const drawerStackRef = useRef<DrawerProps[]>([]);
  useEffect(() => {
    drawerStackRef.current = drawerStack;
  }, [drawerStack]);

  const openDrawer = (
    component: React.ReactNode,
    parameter: any,
    title: string | React.ReactNode,
    closeOnClickOutside?: boolean,
    size?: MantineNumberSize
  ): Promise<any> => {
    return new Promise<any>((resolve) => {
      const newDrawer: DrawerProps = {
        component,
        parameter,
        onClose: resolve,
        title,
        opened: false, // 1️⃣ 초기에는 닫힌 상태
        closeOnClickOutside,
        size,
      };

      // 2️⃣ Drawer 먼저 Stack에 추가
      setDrawerStack((prev) => [...prev, newDrawer]);
      setOpenedStates((prev) => [...prev, false]);

      // 3️⃣ 다음 렌더 타이밍에 opened=true로 변경 (자연스러운 transition 발생)
      requestAnimationFrame(() => {
        setOpenedStates((prev) =>
          prev.map((v, i) => (i === prev.length - 1 ? true : v))
        );
      });
    });
  };

  const closeDrawer = (result?: any) => {
    const stack = drawerStackRef.current;
    const topIndex = stack.length - 1;
    if (topIndex < 0) return;

    setOpenedStates((prev) => prev.map((v, i) => (i === topIndex ? false : v)));

    setTimeout(() => {
      const currentDrawer = stack[topIndex];
      currentDrawer?.onClose?.(result);

      setDrawerStack((prev) => prev.slice(0, topIndex));
      setOpenedStates((prev) => prev.slice(0, topIndex));
    }, TRANSITION_DURATION);
  };

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      {children}
      <Portal>
        {drawerStack.map((drawerProps, index) => (
          <Drawer
            key={index}
            opened={openedStates[index]}
            position="bottom"
            onClose={closeDrawer}
            title={drawerProps.title}
            size={drawerProps.size ?? "70%"}
            closeOnClickOutside={!drawerProps.closeOnClickOutside}
            transitionProps={{
              transition: "slide-up", // 기본 slide-up → 닫힐 때 slide-down으로 작동
              duration: TRANSITION_DURATION,
              timingFunction: "ease",
            }}
            zIndex={1000 + index} // 여러 Drawer가 겹칠 수 있으므로 zIndex 보정
            styles={{
              content: {
                display: "flex",
                flexDirection: "column",
                borderRadius: "0px", // ✅ 먼저 전역 radius 제거
                borderTopLeftRadius:
                  drawerProps.size === "100%" ? "0px" : "16px !important",
                borderTopRightRadius:
                  drawerProps.size === "100%" ? "0px" : "16px !important",
              },
              body: {
                display: "flex",
                flex: 1,
                padding: "0px",
                width: "100%",
              },
              title: {
                width: "100%",
              },
            }}
            closeButtonProps={{
              iconSize: "1.5rem",
              fw: 700,
            }}
          >
            {drawerProps.component}
          </Drawer>
        ))}
      </Portal>
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const drawerContext = useContext(DrawerContext);
  if (!drawerContext) {
    throw new Error("useDrawer must be used within a DrawerStackManager");
  }
  return drawerContext;
};

export default DrawerStackManager;
