// pages/ReceptionPage.tsx
import {
  TicketApiTicketsBackupTicketPostRequest,
  TicketsGet200Response,
  TicketsGet200ResponseRowsInner,
  TicketsGet200ResponseRowsInnerStepStatusEnum,
} from "@sizlcorp/mbk-api-document/dist/models";
import {
  InfiniteData,
  useInfiniteQuery,
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import usePrintPost from "api/print/usePrintPost";
import { mutateTickets, tickets } from "api/tickets/useTicketsQuery";
import { AxiosResponse } from "axios";
import { withResponsiveComponent } from "components/WithResponsiveComponent";
import { receptionStatusList } from "constants/receptionStatusList";
import { receptionSearchType } from "constants/searchType";
import dayjs from "dayjs";
import { useResponsive } from "hook/useResponsive";
import { Dispatch, SetStateAction, useState } from "react";
import { SortDirection } from "react-data-grid";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";
import { getStartAndEndOfDateRange } from "utils/rangeDate";

export interface ReceptionProps {
  receptionData: {
    isMyTicketOnly: boolean;
    setIsMyTicketOnly: (value: boolean) => void;
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
    searchFields: string[];
    setSearchFields: Dispatch<SetStateAction<string[]>>;
    searchType: { value: string; label: string }[];
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    pageSize: string;
    setPageSize: Dispatch<SetStateAction<string>>;
    pageSizeOptions: { value: string; label: string }[];
    sort: string[];
    setSort: Dispatch<SetStateAction<string>>;
    sortColumns: Map<string, SortProps>;
    handleSort: (props: SortProps) => void;
    selectedRows: ReadonlySet<any>;
    setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<any>>>;
    filter: keyof typeof receptionStatusList;
    setFilter: (value: keyof typeof receptionStatusList) => void;
    date: [Date | null, Date | null];
    setDate: (value: [Date | null, Date | null]) => void;
    ticketData: TicketsGet200Response;
    ticketMobileData: TicketsGet200ResponseRowsInner[];
    ticketIdData: TicketsGet200ResponseRowsInner;
  };
  receptionFunctions: {
    printQRLabel: () => void;
    moveToLandingPage: () => void;
    isButtonDisabled: () => boolean;
    deleteTicket: UseMutateFunction<
      AxiosResponse<string[], any>,
      unknown,
      TicketApiTicketsBackupTicketPostRequest,
      unknown
    >;
    deleteTickets: UseMutateFunction<
      AxiosResponse<string[], any>,
      unknown,
      TicketApiTicketsBackupTicketPostRequest,
      unknown
    >;
  };
  mobilePagination?: {
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
  };
}

const ReceptionPage = withResponsiveComponent({
  mobile: () => import("./Mobile"),
  desktop: () => import("./Desktop"),
});

export interface SortProps {
  columnKey: string;
  direction: SortDirection | undefined;
}

const pageSizeOptions = [
  { value: "5", label: "5개" },
  { value: "10", label: "10개" },
  { value: "20", label: "20개" },
  { value: "50", label: "50개" },
  { value: "100", label: "100개" },
];

const filteredSearchType = receptionSearchType
  .filter((type) => type.value)
  .map((type) => type.value);

const today = dayjs().startOf("day").toDate();
const oneWeekAgo = dayjs().subtract(7, "day").startOf("day").toDate();

const ReceptionPageWrapper = () => {
  const userInfo = useRecoilValue(userInfoAtom);
  const { isMobile } = useResponsive();

  const location = useLocation();
  const queryClient = useQueryClient();

  // 해당 계정에게 할당된 접수건인지 여부를 체크
  const [isMyTicketOnly, setIsMyTicketOnly] = useState<boolean>(
    location?.state?.isMyTicketOnly || false
  );
  const [search, setSearch] = useState<string>("");
  const [searchFields, setSearchFields] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<string>("5"); // perPageRows
  const [sort, setSort] = useState<string[]>([]);
  const [filter, setFilter] = useState<keyof typeof receptionStatusList>(
    location?.state?.status || "ALL"
  );
  const [date, setDate] = useState<Date[]>([
    location.state?.date || oneWeekAgo,
    location.state?.date || today,
  ]);

  const [sortColumns, setSortColumns] = useState<Map<string, SortProps>>(
    (new Map() as Map<string, SortProps>).set("incomingAt", {
      columnKey: "incomingAt",
      direction: undefined,
    })
  );

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  // 전체, 접수대기, 점검, 진행, 정비지연, 완료 표시를 위한 쿼리. 버튼 선택에 따라 다른 쿼리를 선택하게 된다 (필터링)
  const stepStatusFilterQuery =
    filter === "ALL"
      ? [
          {
            stepStatus: {
              $ne: TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERED,
            },
          },
          {
            stepStatus: {
              $ne: TicketsGet200ResponseRowsInnerStepStatusEnum.CLOSED,
            },
          },
        ]
      : [{ stepStatus: receptionStatusList[filter] as string[] }];

  // 접수건이 내것만 보기인지 여부에 따라 쿼리를 다르게 한다
  const userQuery = isMyTicketOnly
    ? { assignedAdviserId: { $eq: userInfo?.id } }
    : {};

  // 신속조치나 마감필요항목 데이터 클릭 시 해당 접수건을 갖고 있는 접수건 id를 전달받음. 그거에 따라 쿼리를 다르게 한다
  const ticketQuery = location?.state?.ticketId
    ? { id: { $eq: location?.state?.ticketId } }
    : {};

  // 관리자의 경우 모든 데이터를 조회하기 위함. 딜러의 경우 해당 딜러의 데이터만 조회하기 위함
  const assignedDealerHqCode =
    userInfo?.siteId === 99 ? undefined : userInfo?.site?.dealerHqCode;
  // 관리자의 경우 모든 데이터를 조회하기 위함. 딜러의 경우 해당 딜러의 데이터만 조회하기 위함
  const assignedSiteId =
    userInfo?.siteId === 99 || userInfo?.role === "DEALER"
      ? undefined
      : userInfo?.siteId;

  const sortedColumns = Array.from(sortColumns.values()).filter(
    (value) => value.direction
  );

  // 공통 params 분리
  const ticketsParams = {
    query: [
      JSON.stringify({
        dealerHqCode: assignedDealerHqCode,
        siteId: assignedSiteId,
        $and: [...stepStatusFilterQuery, userQuery, ticketQuery],
        incomingAt: {
          $or: [
            {
              $between: getStartAndEndOfDateRange(date[0], date[1]),
            },
            { $eq: null },
          ],
        },
      }),
    ],
    pageSize: Number(pageSize),
    populate: [
      "stage",
      "site",
      "workbay",
      "carModel",
      "status",
      "createUser",
      "pendingReason",
      "stagesInfo",
      "assignedAdviser",
      "assignedTechnician",
    ],
    search,
    searchFields: searchFields.length ? searchFields : filteredSearchType,
    sort:
      sortedColumns.length < 1
        ? "-id"
        : sortedColumns
            .map((value) =>
              value.direction === "DESC"
                ? `-${value.columnKey}`
                : `${value.columnKey}`
            )
            .join(","),
  };

  const { data: pagedData } = useQuery({
    ...tickets.getView({
      ...ticketsParams,
      page,
    }),
    enabled: typeof isMobile === "boolean" && !isMobile,
    keepPreviousData: true,
  });

  const infiniteQuery = tickets.getViewMobile({
    ...ticketsParams,
    page: 1, // 초기 page, 실제 페이지네이션은 pageParam에서 대체됨
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isInfiniteLoading,
  } = useInfiniteQuery({
    ...infiniteQuery,
    queryFn: ({ pageParam = 1, ...ctx }) =>
      tickets.getViewMobile({ ...ticketsParams, page: pageParam }).queryFn({
        pageParam,
        ...ctx,
      }), // ✅ context를 다시 넘겨줘야 함
    enabled: typeof isMobile === "boolean" && isMobile,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage?.data?.page ?? 1;
      const totalPages = lastPage?.data?.totalPages ?? 1;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) =>
      ({
        ...data,
        flatRows: data.pages.flatMap((page) => page.data?.rows ?? []),
      } as InfiniteData<AxiosResponse<TicketsGet200Response>> & {
        flatRows: TicketsGet200ResponseRowsInner[];
      }),
  });

  const mobileData = data as
    | (InfiniteData<AxiosResponse<TicketsGet200Response>> & {
        flatRows: TicketsGet200ResponseRowsInner[];
      })
    | undefined;

  // 접수건이 다중 선택될 경우, 이전 데이터의 상태를 체크해 봐야 한다. (접수대기 상태면 엑셀접수, 신규접수 버튼 활성화, 그 외는 비활성화)
  const { data: ticketIdData } = useQuery({
    ...tickets.detail({ ticketId: selectedRows.values().next().value }),
    enabled: selectedRows.size === 1,
  });

  // QR 출력
  const { mutate: mutatePrint } = usePrintPost();

  // 접수건 단건 삭제
  const { mutate: deleteTicket } = useMutation(
    (params: TicketApiTicketsBackupTicketPostRequest) =>
      mutateTickets
        .delete(params)
        .mutationFn(params as TicketApiTicketsBackupTicketPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // 접수건 다중 삭제
  const { mutate: deleteTickets } = useMutation(
    (params: TicketApiTicketsBackupTicketPostRequest) =>
      mutateTickets
        .removeDelete(params)
        .mutationFn(params as TicketApiTicketsBackupTicketPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      },
    }
  );

  // QR 출력 함수
  const printQRLabel = () => {
    const values = selectedRows.values();
    const ticketIds = Array.from(values);
    if (selectedRows && selectedRows.size > 0) {
      mutatePrint({
        ticketId: ticketIds,
      });
    }
  };

  // 랜딩 페이지 새 창 띄우기 함수
  const moveToLandingPage = () => {
    const ticketsId = Array.from(selectedRows.values());
    if (selectedRows.size === 1) {
      window.open(
        `${process.env.REACT_APP_MOVE_TO_LANDING_PAGE_URL}/${ticketsId}`
      );
    }
  };

  // sort 하는거 입고(예약)일시, 고객명, 진행상태만 하면 됨. 배열로 받아서 sort에다가 넣으면 된다.
  const handleSort = ({ columnKey, direction }: SortProps) => {
    if (direction === "ASC") {
      setSortColumns((prev) =>
        new Map(prev).set(columnKey, {
          columnKey,
          direction: undefined,
        })
      );
    } else if (direction === "DESC") {
      setSortColumns((prev) =>
        new Map(prev).set(columnKey, {
          columnKey,
          direction: "ASC",
        })
      );
    } else {
      setSortColumns((prev) =>
        new Map(prev).set(columnKey, {
          columnKey,
          direction: "DESC",
        })
      );
    }
  };

  const isButtonDisabled = () => {
    return !(
      selectedRows.size === 1 &&
      !(ticketIdData?.data?.stepStatus === "TEMPORARY_RECEIPTED")
    );
  };

  return (
    <ReceptionPage
      receptionData={{
        isMyTicketOnly,
        setIsMyTicketOnly,
        search,
        setSearch,
        searchFields,
        setSearchFields,
        searchType: receptionSearchType,
        page,
        setPage,
        pageSize,
        setPageSize,
        pageSizeOptions,
        sort,
        setSort,
        sortColumns,
        handleSort,
        selectedRows,
        setSelectedRows,
        filter,
        setFilter,
        date,
        setDate,
        ticketData: pagedData?.data,
        ticketMobileData: mobileData?.flatRows,
        ticketIdData,
      }}
      receptionFunctions={{
        printQRLabel,
        moveToLandingPage,
        isButtonDisabled,
        deleteTicket,
        deleteTickets,
      }}
      mobilePagination={
        isMobile
          ? {
              fetchNextPage,
              hasNextPage,
              isFetchingNextPage,
            }
          : undefined
      }
    />
  );
};

export default ReceptionPageWrapper;
