import { createQueryKeys } from "@lukemorales/query-key-factory";
import { TicketsTodayStatusPostRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { TicketInstance } from "instance/axios";

export const ticketReports = createQueryKeys("ticketReports", {
  all: null,
  postTodayStatus: (
    ticketsTodayStatusPostRequest?: TicketsTodayStatusPostRequest
  ) => {
    return {
      queryKey: ["today", ticketsTodayStatusPostRequest?.assignedTickets],
      queryFn: () =>
        TicketInstance.ticketsTodayStatusPost({
          ticketsTodayStatusPostRequest,
        }),
    };
  },
  postWeekStatus: (
    ticketsTodayStatusPostRequest?: TicketsTodayStatusPostRequest
  ) => {
    return {
      queryKey: ["week", ticketsTodayStatusPostRequest?.assignedTickets],
      queryFn: () =>
        TicketInstance.ticketsWeekStatusPost({
          ticketsTodayStatusPostRequest,
        }),
    };
  },
  postMonthStatus: (
    ticketsTodayStatusPostRequest?: TicketsTodayStatusPostRequest
  ) => {
    return {
      queryKey: ["month", ticketsTodayStatusPostRequest?.assignedTickets],
      queryFn: () =>
        TicketInstance.ticketsMonthStatusPost({
          ticketsTodayStatusPostRequest,
        }),
    };
  },

  postUrgencyStatus: (
    ticketsTodayStatusPostRequest?: TicketsTodayStatusPostRequest
  ) => {
    return {
      queryKey: [
        "urgencyStatus",
        ticketsTodayStatusPostRequest?.assignedTickets,
      ],
      queryFn: () =>
        TicketInstance.ticketsUrgencyStatusPost({
          ticketsTodayStatusPostRequest,
        }),
    };
  },
  postToCloseStatus: (
    ticketsTodayStatusPostRequest?: TicketsTodayStatusPostRequest
  ) => {
    return {
      queryKey: [
        "toCloseStatus",
        ticketsTodayStatusPostRequest?.assignedTickets,
      ],
      queryFn: () =>
        TicketInstance.ticketsToCloseTicketsPost({
          ticketsTodayStatusPostRequest,
        }),
    };
  },
});
