import { Box, Center, NavLink, Text } from "@mantine/core";
import {
  IconCheckupList,
  IconHome2,
  IconLayoutBoard,
  IconNotes,
  IconSettings,
  IconSettingsPause,
  IconSettingsSearch,
  IconUserEdit,
  IconUserSearch,
} from "@tabler/icons-react";
import { Loading } from "components/Loading";
import { ReactComponent as DashboardLogo } from "images/svg/Dashboard_logo.svg";
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";

interface MenuListType {
  id: number;
  parentId: number | null;
  path: string;
  name: JSX.Element | string;
  icon: JSX.Element;
  role?: string[];
}

const menuList: MenuListType[] = [
  {
    id: 1,
    parentId: null,
    path: "/dashboard",
    name: (
      <Text fz="md" c="gray">
        Dashboards
      </Text>
    ),
    icon: <IconLayoutBoard color="gray" />,
    role: ["ADVISER", "TECHNICIAN", "ADMIN"],
  },
  {
    id: 2,
    parentId: null,
    path: "/reception",
    name: (
      <Text fz="md" c="gray">
        접수현황
      </Text>
    ),
    icon: <IconNotes color="gray" />,
    role: ["ADVISER", "TECHNICIAN", "ADMIN", "DEALER"],
  },
  {
    id: 3,
    parentId: null,
    path: "/receptionArchive",
    name: (
      <Text fz="md" c="gray">
        마감현황
      </Text>
    ),
    icon: <IconCheckupList color="gray" />,
    role: ["ADVISER", "TECHNICIAN", "ADMIN", "DEALER"],
  },
  {
    id: 5,
    parentId: null,
    path: "/informationManagement",
    name: (
      <Text fz="md" c="gray">
        내 정보 관리
      </Text>
    ),
    icon: <IconUserEdit color="gray" />,
  },
  {
    id: 6,
    parentId: null,
    path: "#",
    name: (
      <Text fz="md" c="gray">
        설정
      </Text>
    ),
    icon: <IconSettings color="gray" />,
    role: ["ADMIN"],
  },
  {
    id: 4,
    parentId: 6,
    path: "/users",
    name: (
      <Text fz="md" c="gray">
        사용자 관리
      </Text>
    ),
    icon: <IconUserSearch color="gray" />,
    role: ["ADMIN"],
  },
  {
    id: 7,
    parentId: 6,
    path: "/siteConfig",
    name: (
      <Text fz="md" c="gray">
        센터 관리
      </Text>
    ),
    icon: <IconHome2 color="gray" />,
    role: ["ADMIN"],
  },
  {
    id: 8,
    parentId: 6,
    path: "/pendingReason",
    name: (
      <Text fz="md" c="gray">
        지연사유 관리
      </Text>
    ),
    icon: <IconSettingsPause color="gray" />,
    role: ["ADMIN"],
  },
  // {
  //   id: 13,
  //   parentId: 6,
  //   path: "/notice",
  //   name: (
  //     <Text fz="md" c="gray">
  //       공지사항 관리
  //     </Text>
  //   ),
  //   icon: <IconBellExclamation color="gray" />,
  //   role: ["ADMIN"],
  // },
  // {
  //     id: 9,
  //     parentId: 6,
  //     path: "/processType",
  //     name: <Text fz="md" c="gray">상세정비 관리</Text>,
  //     icon: <IconSettingsCog color="gray" />,
  //     role: ["ADMIN"]
  // },
  {
    id: 10,
    parentId: null,
    path: "#",
    name: (
      <Text fz="md" c="gray">
        데이터 관리
      </Text>
    ),
    icon: <IconSettings color="gray" />,
    role: ["ADMIN", "DEALER", "MBK"],
  },
  {
    id: 11,
    parentId: 10,
    path: "/dataManagement-dealer",
    name: (
      <Text fz="md" c="gray">
        딜러사
      </Text>
    ),
    icon: <IconSettingsSearch color="gray" />,
    role: ["DEALER", "MBK"],
  },
  {
    id: 12,
    parentId: 10,
    path: "/dataManagement-center",
    name: (
      <Text fz="md" c="gray">
        서비스 센터
      </Text>
    ),
    icon: <IconSettingsSearch color="gray" />,
    role: ["ADMIN", "DEALER", "MBK"],
  },
];

// const roleList = [
//     "ADMIN",
//     "ADVISOR",
//     "TECHNICIAN",
// ]

// 스타일 상수 정의
const navLinkStyles =
  (isActive: boolean) => (theme: { colors: { gray: any[] } }) => ({
    root: {
      "&:hover": {
        backgroundColor: theme.colors.gray[3],
      },
      backgroundColor: isActive ? theme.colors.gray[3] : "transparent",
    },
  });

const generateMenu = (
  menuList: MenuListType[],
  parentId: number | null = null,
  navigate: NavigateFunction,
  location: Location<any>,
  role: string,
  isAdmin: boolean = false
) => {
  return menuList
    .filter((menu) => menu.parentId === parentId)
    .filter((menu) => isAdmin || !menu.role || menu.role.includes(role)) // isAdmin이면 필터링 없이 모든 메뉴 표시
    .map((menu) => {
      const children = generateMenu(
        menuList,
        menu.id,
        navigate,
        location,
        role,
        isAdmin
      );
      const isActive = location.pathname === menu.path;

      if (children.length > 0) {
        return (
          <NavLink
            key={menu.id}
            label={menu.name}
            icon={menu.icon}
            c="gray"
            styles={navLinkStyles(isActive)}
            p="1rem"
            defaultOpened
          >
            {children}
          </NavLink>
        );
      }
      return (
        <NavLink
          key={menu.id}
          label={menu.name}
          icon={menu.icon}
          c="gray"
          onClick={() => navigate(menu.path)}
          styles={navLinkStyles(isActive)}
          p="1rem"
        />
      );
    });
};

export const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useRecoilValue(userInfoAtom);

  if (!userInfo) return <Loading />;

  const role = userInfo?.role ?? "";
  const isAdmin = userInfo?.siteId === 99; // siteId가 99인 경우 모든 메뉴 표시

  return (
    <Box w={210} pos="absolute" bg="black" h="100%">
      <Center p="2rem">
        <DashboardLogo
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/dashboard")}
        />
      </Center>
      {generateMenu(menuList, null, navigate, location, role, isAdmin)}
    </Box>
  );
};
