// withResponsiveComponent.tsx
import { lazy, Suspense } from "react";
import { useResponsiveMode } from "hook/useResponsiveMode";
import { Loading } from "components/Loading";

type Options = {
  mobile: () => Promise<{ default: React.ComponentType<any> }>;
  desktop: () => Promise<{ default: React.ComponentType<any> }>;
};

export const withResponsiveComponent = ({ mobile, desktop }: Options) => {
  const MobileComponent = lazy(mobile);
  const DesktopComponent = lazy(desktop);

  return function ResponsiveWrapper(props: any) {
    const { device } = useResponsiveMode();

    if (!device) return null;

    const ComponentToRender =
      device === "mobile" ? MobileComponent : DesktopComponent;

    return (
      <Suspense fallback={<Loading />}>
        <ComponentToRender {...props} />
      </Suspense>
    );
  };
};
