// hooks/useResponsive.ts
import { useMediaQuery } from "@mantine/hooks";

export const useResponsive = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isTabletV = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isTabletH = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  let device: "mobile" | "tabletV" | "tabletH" | "desktop" = "desktop";

  if (isMobile) device = "mobile";
  else if (isTabletV || isTabletH) device = "mobile"; // 임시 mobile 처리. 현재 mobile은 고려하고 있지 않음.

  return { isMobile, isTabletV, isTabletH, isDesktop, device };
};

// desktop을 1280으로 놓고 태블릿 세로 768이상 태블릿 가로 1024이상
