import styled from "@emotion/styled";
import {
  Button,
  Divider,
  Flex,
  MultiSelect,
  Pagination,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  AuthSignupPost201ResponseRoleEnum,
  SitesGet200ResponseRowsInner,
} from "@sizlcorp/mbk-api-document/dist/models";
import {
  IconBuilding,
  IconCloudDownload,
  IconRefresh,
  IconSearch,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useDealersQuery } from "api/dealers/useDealersQuery";
import { site } from "api/site/useSiteQuery";
import BaseBox from "components/Box/BaseBox";
import { PopoverDatePicker } from "components/Date/PopoverDatePicker";
import { Loading } from "components/Loading";
import dayjs from "dayjs";
import { useLoading } from "hook/useLoading";
import { MasterInstance } from "instance/axios";
import { CalendarDate } from "components/Date/Calendar";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";
import { convertFormatDuration } from "utils/convert";
import { getStartAndEndOfDateRange } from "utils/rangeDate";
import XLSX from "xlsx-js-style";

const DataManagement = {
  Box: BaseBox,
};

const headerStyle = {
  font: { bold: true, color: { rgb: "000000" }, name: "MBK CorpoS", sz: 13 },
  fill: { fgColor: { rgb: "BC8F8F" } },
  alignment: { horizontal: "center", vertical: "center" },
  border: {
    left: { style: "thin", color: { auto: 1 } },
    right: { style: "thin", color: { auto: 1 } },
    top: { style: "thin", color: { auto: 1 } },
    bottom: { style: "thin", color: { auto: 1 } },
  },
};
const dataStyle = {
  font: { color: { rgb: "000000" }, name: "MBK CorpoS", sz: 11 },
  fill: { fgColor: { rgb: "FFFAFA" } },
  alignment: { horizontal: "center", vertical: "center" },
  border: {
    left: { style: "thin", color: { auto: 1 } },
    right: { style: "thin", color: { auto: 1 } },
    top: { style: "thin", color: { auto: 1 } },
    bottom: { style: "thin", color: { auto: 1 } },
  },
};

const { MBK, DEALER, ADMIN } = AuthSignupPost201ResponseRoleEnum;
export const DataManagementDealerPage = () => {
  const level = "DEALER";
  // 권한
  const userInfo = useRecoilValue(userInfoAtom);

  const [isLoading, executeWithLoading] = useLoading(); // 엑셀 다운로드 시 로딩 상태
  const [searchLoading, setSearchLoading] = useState(false); // 조회 버튼 클릭 시 로딩 상태

  // 서비스센터만 조회할 때는 검색결과쪽 탭을 서비스센터 레벨 체크
  // 딜러사만 조회할 때는 딜러사 레벨 체크
  // 딜러사, 서비스센터 둘 다 조회할 때는 딜러사 레벨 체크
  const [dealers, setDealers] = useState<string[]>([]);
  const [centers, setCenters] = useState<string[]>([]);

  const [selectedDateRange, setSelectedDateRange] = useState<CalendarDate>([
    new Date(),
    new Date(),
  ]);

  const [dealerPage, setDealerPage] = useState<number>(1);
  const [perPageRows, setPerPageRows] = useState(10);

  const [queryData, setQueryData] = useState<any>([]);

  // 초기화 함수, auth에 따라 dealers와 centers 설정
  const setInitialize = useCallback(() => {
    setSelectedDateRange([new Date(), new Date()]);

    if (
      (userInfo?.role === DEALER || userInfo?.role === ADMIN) &&
      userInfo?.site?.dealerHqCode
    ) {
      setDealers([userInfo?.site?.dealerHqCode]); // 여기에 실제 딜러 데이터를 처리
    } else {
      setDealers([]); // 필요에 따라 초기화
    }

    if (userInfo?.role === ADMIN && userInfo?.site?.id) {
      setCenters([userInfo.site.id.toString()]);
    } else {
      setCenters([]); // 필요에 따라 초기화
    }
  }, [userInfo]);

  // auth 값이 변경될 때마다 setInitialize 실행
  useEffect(() => {
    if (userInfo) {
      setInitialize(); // auth가 변경되면 초기화
    }
  }, [userInfo, setInitialize]);

  // 딜러사가 변경될 때만 서비스센터를 초기화하는 useEffect
  useEffect(() => {
    if (userInfo?.role === MBK) {
      setCenters([]); // 딜러사가 변경되면 서비스센터 초기화
    }
  }, [dealers]); // dealers가 변경될 때만 실행

  // 딜러사와 서비스센터 선택에 따라 queryData를 업데이트하는 useEffect
  useEffect(() => {
    // 업데이트할 queryData 초기화 (기존 딜러사와 서비스센터 필터 제거)
    let updatedQueryData = queryData.filter(
      (item: any) => !item.dealerHqCode && !item.siteId
    );

    // 딜러사만 선택된 경우
    if (dealers.length > 0 && centers.length === 0) {
      updatedQueryData = [
        ...updatedQueryData,
        { dealerHqCode: { $in: dealers } }, // 새로운 딜러사 필터 추가
      ];
    }

    // 서비스센터만 선택된 경우
    if (centers.length > 0 && dealers.length === 0) {
      updatedQueryData = [
        ...updatedQueryData,
        { siteId: { $in: centers } }, // 새로운 서비스센터 필터 추가
      ];
    }

    // 둘 다 선택된 경우, 서비스센터만 queryData에 추가
    if (centers.length > 0 && dealers.length > 0) {
      updatedQueryData = [
        ...updatedQueryData,
        { siteId: { $in: centers } }, // 서비스센터 필터만 추가
      ];
    }

    // queryData 업데이트
    setQueryData(updatedQueryData);
  }, [dealers, centers]); // dealers 또는 centers가 변경될 때 실행

  // 딜러사만 있을 때 검색 - 딜러사만 queryData에 추가
  // 서비스센터만 있을 때 검색 - 서비스센터만 queryData에 추가
  // 둘 다 있을 때 검색 - 서비스센터만 queryData에 추가
  // 서비스센터에 데이터가 있을 때, 딜러사를 선택하면 서비스센터 데이터 초기화
  // 딜러사를 선택했을 때 서비스센터는 딜러사에 해당되는 서비스센터만 표시가 되어야 한다.

  const { data: ticketDealerData, refetch } = useQuery({
    ...site.get(
      {
        query: [
          JSON.stringify({
            $and: [
              {
                dealerHqCode: {
                  $or: dealers,
                },
              },
              {
                id: {
                  $or: centers,
                },
              },
              // 다른 조건이 있으면 추가 가능
              {
                name: {
                  $notLike: "%사고수리%", // 추후 제거 필요 (phase 2 시작 시) - 2025.02.04 by rebi13
                },
              },
            ],
          }),
        ],
        page: dealerPage,
        pageSize: perPageRows,
        populate: ["dealer", "dealerInfo"],
        sort: "-id",
      },
      {
        params: {
          incomingAt: getStartAndEndOfDateRange(
            selectedDateRange[0],
            selectedDateRange[1]
          ),
        },
      }
    ),
    enabled:
      searchLoading && (userInfo?.role !== ADMIN || userInfo?.siteId === 99),
    onSuccess: () => {
      setSearchLoading(false);
    },
  });

  const { data: dealerData } = useDealersQuery({
    sort: "seq",
  });

  const { data: siteData } = useQuery(
    site.find({
      query: [
        JSON.stringify({
          $and: [
            {
              dealerHqCode: {
                $or: dealers,
              },
            },
          ],
        }),
      ],
    })
  );

  const dealerLevelData = ticketDealerData?.data.rows;
  const centerData = siteData?.data.map((site) => ({
    value: site.id.toString(),
    label: site.name,
  }));

  const excelDownLoad = async () => {
    let colWidths;
    let headerRow;
    let dataRows;
    let rows;

    const response = await MasterInstance.sitesFindPost(
      {
        query: [
          JSON.stringify({
            $and: [
              {
                dealerHqCode: {
                  $or: dealers,
                },
              },
              {
                id: {
                  $or: centers,
                },
              },
              // 다른 조건이 있으면 추가 가능
              {
                name: {
                  $notLike: "%사고수리%", // 추후 제거 필요 (phase 2 시작 시) - 2025.02.04 by rebi13
                },
              },
            ],
          }),
        ],
        populate: ["dealer", "dealerInfo"],
        sort: "-id",
      },
      {
        params: {
          incomingAt: getStartAndEndOfDateRange(
            selectedDateRange[0],
            selectedDateRange[1]
          ),
        },
      }
    );

    const data = response.data;

    // 열의 폭을 정의
    colWidths = [
      80, 136, 240, 120, 136, 112, 152, 176, 160, 136, 224, 136, 224, 112, 112,
      112,
    ];

    headerRow = [
      { v: "회사코드", t: "s", s: headerStyle },
      { v: "서비스 센터 소속", t: "s", s: headerStyle },
      { v: "서비스 센터명", t: "s", s: headerStyle },
      { v: "VST 접수 건수", t: "s", s: headerStyle },
      { v: "알림톡 발송 건수", t: "s", s: headerStyle },
      { v: "알림톡 사용률", t: "s", s: headerStyle },
      { v: "평균 정비 소요시간", t: "s", s: headerStyle },
      { v: "평균 정비마감 소요시간", t: "s", s: headerStyle },
      { v: "랜딩페이지 열람횟수", t: "s", s: headerStyle },
      { v: "정비 전 지연 건수", t: "s", s: headerStyle },
      { v: "정비 전 지연 알림톡 사용 건수", t: "s", s: headerStyle },
      { v: "정비 중 지연 건수", t: "s", s: headerStyle },
      { v: "정비 중 지연 알림톡 사용 건수", t: "s", s: headerStyle },
      { v: "출고 전 지연 건수", t: "s", s: headerStyle },
      { v: "출고 전 지연 알림톡 사용 건수", t: "s", s: headerStyle },
      { v: "출고완료 건수", t: "s", s: headerStyle },
      { v: "마감완료 건수", t: "s", s: headerStyle },
      { v: "강제마감 건수", t: "s", s: headerStyle },
    ];

    dataRows =
      (data &&
        data.map((dealer: SitesGet200ResponseRowsInner) => [
          { v: dealer.dealer?.dealerHqCode, t: "s", s: dataStyle },
          { v: dealer.dealer?.dealerHq, t: "s", s: dataStyle },
          { v: dealer?.name, t: "s", s: dataStyle },
          { v: dealer.dealerInfo?.ticketCount, t: "s", s: dataStyle },
          { v: dealer.dealerInfo?.alarmCount, t: "s", s: dataStyle },
          {
            v: dealer.dealerInfo?.alarmUsageRate + "%",
            t: "s",
            s: dataStyle,
          },
          // 엑셀 데이터 형식을 사용자 정의 서식으로 변경하는 방법.
          // { v: dealer.dealerInfo?.totalConfirmDurationTime ?? "", t: 's', s: dataStyle },
          // 논의 후 적용 2024.09.26 by jswon
          {
            v: (dealer.dealerInfo?.totalConfirmDurationTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          // { v: dealer.dealerInfo?.totalClosedDurationTime ?? "", t: 's', s: dataStyle },
          {
            v: (dealer.dealerInfo?.totalClosedDurationTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          { v: "-", t: "s", s: dataStyle },
          { v: dealer.dealerInfo?.acceptPendingCount, t: "s", s: dataStyle },
          {
            v: dealer.dealerInfo?.acceptPendingAlarmCount ?? "",
            t: "s",
            s: dataStyle,
          },
          { v: dealer.dealerInfo?.stagePendingCount, t: "s", s: dataStyle }, // 직급
          {
            v: dealer.dealerInfo?.stagePendingAlarmCount,
            t: "s",
            s: dataStyle,
          },
          { v: dealer.dealerInfo?.confirmPendingCount, t: "s", s: dataStyle },
          {
            v: dealer.dealerInfo?.confirmPendingAlarmCount,
            t: "s",
            s: dataStyle,
          },
          { v: dealer.dealerInfo?.deliveredCount, t: "s", s: dataStyle },
          { v: dealer.dealerInfo?.closedCount, t: "s", s: dataStyle },
          { v: dealer.dealerInfo?.forceClosedCount, t: "s", s: dataStyle },
        ])) ??
      [];

    rows = [headerRow, ...dataRows];

    // Excel 파일 생성 및 다운로드
    const wb = XLSX.utils.book_new();

    // cols 속성을 사용하여 각 열의 폭을 조절
    const cols = colWidths?.map((width) => ({ wpx: width })) ?? [];
    // 새로운 Sheet 객체 생성
    const ws = XLSX.utils.aoa_to_sheet(rows);

    // cols 속성 적용
    ws["!cols"] = cols;

    // workbook에 추가
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // 파일 다운로드
    XLSX.writeFile(
      wb,
      `${level}_${dayjs(selectedDateRange[0]).format("YYYY-MM-DD")}~${dayjs(
        selectedDateRange[1]
      ).format("YYYY-MM-DD")}.xlsx`
    );

    return;
  };

  return (
    <Flex direction="column" gap="xs" p="lg">
      <Title>데이터 관리</Title>
      <Title order={2}>검색조건 설정</Title>
      <DataManagement.Box>
        <Flex direction="column" gap="xs" w="100%">
          <Flex w="100%" justify="flex-start" gap="xl">
            <PopoverDatePicker
              date={{ selectedDateRange, setSelectedDateRange }}
            />
            <MultiSelect
              searchable
              w="15rem"
              rightSection={<IconSearch />}
              placeholder="딜러사명"
              label={
                <Flex align="center">
                  <IconBuilding />
                  <Text fz="lg" fw={700}>
                    딜러사
                  </Text>
                </Flex>
              }
              data={dealerData || []}
              value={dealers}
              onChange={setDealers}
              disabled={
                userInfo?.siteId !== 99 &&
                (userInfo?.role === ADMIN || userInfo?.role === DEALER)
              }
            />
            <MultiSelect
              searchable
              w="15rem"
              rightSection={<IconSearch />}
              placeholder="서비스센터명"
              label={
                <Flex align="center">
                  <IconBuilding />
                  <Text fz="lg" fw={700}>
                    서비스센터
                  </Text>
                </Flex>
              }
              data={centerData || []}
              value={centers}
              onChange={setCenters}
              disabled={userInfo?.siteId !== 99 && userInfo?.role === ADMIN}
            />
          </Flex>

          <Divider />
          <Flex justify="flex-end" gap="sm">
            <Button
              color="indigo"
              leftIcon={<IconRefresh />}
              onClick={() => {
                setInitialize();
              }}
            >
              초기화
            </Button>
            <Button
              leftIcon={<IconSearch />}
              onClick={() => {
                setDealerPage(1);
                // setShouldFetch(true);
                // refetch();
                setSearchLoading(true);
              }}
            >
              조회
            </Button>
          </Flex>
        </Flex>
      </DataManagement.Box>

      <Title order={2}>조회 결과</Title>
      <DataManagement.Box>
        <Flex direction="column" gap="md" w="100%">
          <Flex justify="flex-end">
            <Button
              disabled={
                !(level.length > 0) &&
                !(dealerLevelData && dealerLevelData.length > 0)
              }
              color="green"
              leftIcon={<IconCloudDownload />}
              onClick={() => executeWithLoading(excelDownLoad)}
            >
              엑셀추출
            </Button>
          </Flex>
          {(searchLoading || isLoading) && <Loading />}
          {(userInfo?.role === MBK ||
            userInfo?.role === DEALER ||
            userInfo?.siteId === 99) &&
            dealerLevelData && (
              <>
                <Flex
                  direction="column"
                  align="flex-start"
                  bg="white"
                  w="100%"
                  style={{ overflowY: "auto" }}
                >
                  <Table withColumnBorders withBorder>
                    <thead>
                      <tr>
                        <Th minWidth={5}>
                          <Text fz="lg" ta="center">
                            회사코드
                          </Text>
                        </Th>
                        <Th minWidth={8.5}>
                          <Text fz="lg" ta="center">
                            서비스 센터 소속
                          </Text>
                        </Th>
                        {/* <Th><Text fz="lg">지역</Text></Th> */}
                        <Th minWidth={15}>
                          <Text fz="lg" ta="center">
                            서비스 센터명
                          </Text>
                        </Th>
                        <Th minWidth={7.5}>
                          <Text fz="lg" ta="center">
                            VST 접수 건수
                          </Text>
                        </Th>
                        <Th minWidth={8.5}>
                          <Text fz="lg" ta="center">
                            알림톡 발송 건수
                          </Text>
                        </Th>
                        <Th minWidth={7}>
                          <Text fz="lg" ta="center">
                            알림톡 사용률
                          </Text>
                        </Th>
                        <Th minWidth={9.5}>
                          <Text fz="lg" ta="center">
                            평균 정비 소요시간
                          </Text>
                        </Th>
                        <Th minWidth={11}>
                          <Text fz="lg" ta="center">
                            평균 정비마감 소요시간
                          </Text>
                        </Th>
                        <Th minWidth={10}>
                          <Text fz="lg" ta="center">
                            랜딩페이지 열람횟수
                          </Text>
                        </Th>
                        <Th minWidth={8.5}>
                          <Text fz="lg" ta="center">
                            정비 전 지연 건수
                          </Text>
                        </Th>
                        <Th minWidth={14}>
                          <Text fz="lg" ta="center">
                            정비 전 지연 알림톡 사용 건수
                          </Text>
                        </Th>
                        <Th minWidth={8.5}>
                          <Text fz="lg" ta="center">
                            정비 중 지연 건수
                          </Text>
                        </Th>
                        <Th minWidth={14}>
                          <Text fz="lg" ta="center">
                            정비 중 지연 알림톡 사용 건수
                          </Text>
                        </Th>
                        <Th minWidth={8.5}>
                          <Text fz="lg" ta="center">
                            출고 전 지연 건수
                          </Text>
                        </Th>
                        <Th minWidth={14}>
                          <Text fz="lg" ta="center">
                            출고 전 지연 알림톡 사용 건수
                          </Text>
                        </Th>
                        <Th minWidth={7}>
                          <Text fz="lg" ta="center">
                            출고완료 건수
                          </Text>
                        </Th>
                        <Th minWidth={7}>
                          <Text fz="lg" ta="center">
                            마감완료 건수
                          </Text>
                        </Th>
                        <Th minWidth={7}>
                          <Text fz="lg" ta="center">
                            강제마감 건수
                          </Text>
                        </Th>
                      </tr>
                    </thead>
                    <tbody>
                      {dealerLevelData.length > 0 ? (
                        dealerLevelData?.map(
                          (dealer: SitesGet200ResponseRowsInner) => (
                            <tr key={dealer.id}>
                              <td>
                                <Text fz="lg">
                                  {dealer?.dealer?.dealerHqCode}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg">{dealer?.dealer?.dealerHq}</Text>
                              </td>
                              <td>
                                <Text fz="lg">{dealer?.name}</Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.ticketCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.alarmCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.alarmUsageRate}%
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg">
                                  {convertFormatDuration(
                                    dealer?.dealerInfo
                                      ?.totalConfirmDurationTime ?? 0
                                  )}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg">
                                  {convertFormatDuration(
                                    dealer?.dealerInfo
                                      ?.totalClosedDurationTime ?? 0
                                  )}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {/* 랜딩페이지 열람 횟수 */}-
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.acceptPendingCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.acceptPendingAlarmCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.stagePendingCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.stagePendingAlarmCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.confirmPendingCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.confirmPendingAlarmCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.deliveredCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.closedCount}
                                </Text>
                              </td>
                              <td>
                                <Text fz="lg" ta="end">
                                  {dealer?.dealerInfo?.forceClosedCount}
                                </Text>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={20}>
                            <Text fz="lg">데이터가 존재하지 않습니다.</Text>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Flex>
                <Pagination
                  value={dealerPage}
                  total={ticketDealerData?.data?.totalPages ?? (0 as number)}
                  onChange={(value) => {
                    setDealerPage(value);
                    setSearchLoading(true);
                  }}
                  position="center"
                />
              </>
            )}
        </Flex>
      </DataManagement.Box>
    </Flex>
  );
};

export const Th = styled.th<{ minWidth?: number; width?: number }>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;
