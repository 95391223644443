// hooks/useResponsiveMode.ts
import { useResponsive } from "hook/useResponsive";

const isDevMode = process.env.REACT_APP_DEV_MODE === "true";

export const useResponsiveMode = () => {
  const { isMobile, isTabletV, isTabletH, isDesktop, device } = useResponsive();

  const deviceMode: "mobile" | "tablet" | "desktop" = isDevMode
    ? device
    : "desktop";

  return {
    isMobileMode: isDevMode && isMobile,
    isTabletMode: isDevMode && (isTabletV || isTabletH),
    isDesktopMode: !isDevMode || isDesktop,
    device: deviceMode, // 추가: 환경에 따라 강제된 device 값
  };
};
