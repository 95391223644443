import styled from "@emotion/styled";
import {
  Badge,
  Button,
  Divider,
  Flex,
  MultiSelect,
  Pagination,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  AuthSignupPost201ResponseRoleEnum,
  TicketsGet200ResponseRowsInner,
} from "@sizlcorp/mbk-api-document/dist/models";
import {
  IconBuilding,
  IconCloudDownload,
  IconRefresh,
  IconSearch,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useDealersQuery } from "api/dealers/useDealersQuery";
import { site } from "api/site/useSiteQuery";
import { tickets } from "api/tickets/useTicketsQuery";
import BaseBox from "components/Box/BaseBox";
import { PopoverDatePicker } from "components/Date/PopoverDatePicker";
import { Loading } from "components/Loading";
import dayjs from "dayjs";
import { useLoading } from "hook/useLoading";
import { TicketUserViewInstance } from "instance/axios";
import { CalendarDate } from "components/Date/Calendar";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";
import { convertFormatDuration } from "utils/convert";
import { getStartAndEndOfDateRange } from "utils/rangeDate";
import XLSX from "xlsx-js-style";

const DataManagement = {
  Box: BaseBox,
};

const headerStyle = {
  font: { bold: true, color: { rgb: "000000" }, name: "MBK CorpoS", sz: 13 },
  fill: { fgColor: { rgb: "BC8F8F" } },
  alignment: { horizontal: "center", vertical: "center" },
  border: {
    left: { style: "thin", color: { auto: 1 } },
    right: { style: "thin", color: { auto: 1 } },
    top: { style: "thin", color: { auto: 1 } },
    bottom: { style: "thin", color: { auto: 1 } },
  },
};
const dataStyle = {
  font: { color: { rgb: "000000" }, name: "MBK CorpoS", sz: 11 },
  fill: { fgColor: { rgb: "FFFAFA" } },
  alignment: { horizontal: "center", vertical: "center" },
  border: {
    left: { style: "thin", color: { auto: 1 } },
    right: { style: "thin", color: { auto: 1 } },
    top: { style: "thin", color: { auto: 1 } },
    bottom: { style: "thin", color: { auto: 1 } },
  },
};

const { MBK, DEALER, ADMIN } = AuthSignupPost201ResponseRoleEnum;
export const DataManagementCenterPage = () => {
  const level = "SERVICE";
  // 권한
  const userInfo = useRecoilValue(userInfoAtom);

  const [isLoading, executeWithLoading] = useLoading(); // 엑셀 다운로드 시 로딩 상태
  const [searchLoading, setSearchLoading] = useState<boolean>(false); // 조회 버튼 클릭 시 로딩 상태

  // 서비스센터만 조회할 때는 검색결과쪽 탭을 서비스센터 레벨 체크
  // 딜러사만 조회할 때는 딜러사 레벨 체크
  // 딜러사, 서비스센터 둘 다 조회할 때는 딜러사 레벨 체크
  const [dealers, setDealers] = useState<string[]>([]);
  const [centers, setCenters] = useState<string[]>([]);

  const [selectedDateRange, setSelectedDateRange] = useState<CalendarDate>([
    new Date(),
    new Date(),
  ]);

  const [centerPage, setCenterPage] = useState<number>(1);
  const [perPageRows, setPerPageRows] = useState(10);

  const [queryData, setQueryData] = useState<any>([]);

  // 초기화 함수, auth에 따라 dealers와 centers 설정
  const setInitialize = useCallback(() => {
    setSelectedDateRange([new Date(), new Date()]);

    if (
      (userInfo?.role === DEALER || userInfo?.role === ADMIN) &&
      userInfo?.site?.dealerHqCode
    ) {
      setDealers([userInfo?.site?.dealerHqCode]); // 여기에 실제 딜러 데이터를 처리
    } else {
      setDealers([]); // 필요에 따라 초기화
    }

    if (userInfo?.role === ADMIN && userInfo?.site?.id) {
      setCenters([userInfo.site.id.toString()]);
    } else {
      setCenters([]); // 필요에 따라 초기화
    }
  }, [userInfo]);

  // auth 값이 변경될 때마다 setInitialize 실행
  useEffect(() => {
    if (userInfo) {
      setInitialize(); // auth가 변경되면 초기화
    }
  }, [userInfo, setInitialize]);

  // 딜러사가 변경될 때만 서비스센터를 초기화하는 useEffect
  useEffect(() => {
    if (userInfo?.role === MBK) {
      setCenters([]); // 딜러사가 변경되면 서비스센터 초기화
    }
  }, [dealers]); // dealers가 변경될 때만 실행

  // 딜러사와 서비스센터 선택에 따라 queryData를 업데이트하는 useEffect
  useEffect(() => {
    // 업데이트할 queryData 초기화 (기존 딜러사와 서비스센터 필터 제거)
    let updatedQueryData = queryData.filter(
      (item: any) => !item.dealerHqCode && !item.siteId
    );

    // 딜러사만 선택된 경우
    if (dealers.length > 0 && centers.length === 0) {
      updatedQueryData = [
        ...updatedQueryData,
        { dealerHqCode: { $in: dealers } }, // 새로운 딜러사 필터 추가
      ];
    }

    // 서비스센터만 선택된 경우
    if (centers.length > 0 && dealers.length === 0) {
      updatedQueryData = [
        ...updatedQueryData,
        { siteId: { $in: centers } }, // 새로운 서비스센터 필터 추가
      ];
    }

    // 둘 다 선택된 경우, 서비스센터만 queryData에 추가
    if (centers.length > 0 && dealers.length > 0) {
      updatedQueryData = [
        ...updatedQueryData,
        { siteId: { $in: centers } }, // 서비스센터 필터만 추가
      ];
    }

    // queryData 업데이트
    setQueryData(updatedQueryData);
  }, [dealers, centers]); // dealers 또는 centers가 변경될 때 실행

  // 딜러사만 있을 때 검색 - 딜러사만 queryData에 추가
  // 서비스센터만 있을 때 검색 - 서비스센터만 queryData에 추가
  // 둘 다 있을 때 검색 - 서비스센터만 queryData에 추가
  // 서비스센터에 데이터가 있을 때, 딜러사를 선택하면 서비스센터 데이터 초기화
  // 딜러사를 선택했을 때 서비스센터는 딜러사에 해당되는 서비스센터만 표시가 되어야 한다.

  const { data: ticketCenterData, isLoading: queryIsLoading } = useQuery({
    ...tickets.getView({
      query: [
        JSON.stringify({
          $and: [
            {
              incomingAt: {
                $or: [
                  {
                    $between: getStartAndEndOfDateRange(
                      selectedDateRange[0],
                      selectedDateRange[1]
                    ),
                  },
                ],
              },
            },
            ...queryData,
          ],
        }),
      ],
      page: centerPage,
      pageSize: perPageRows,
      populate: [
        "serviceCenterInfo",
        "assignedTechnician",
        "assignedAdviser",
        "site",
        "dealer",
      ],
      sort: "-id",
    }),
    enabled: searchLoading,
    onSuccess: () => {
      setSearchLoading(false);
    },
  });

  const { data: dealerData } = useDealersQuery({
    sort: "seq",
  });

  const { data: siteData } = useQuery(
    site.find({
      query: [
        JSON.stringify({
          $and: [
            {
              dealerHqCode: {
                $or: dealers,
              },
            },
          ],
        }),
      ],
    })
  );

  const ticketData = ticketCenterData?.data.rows;
  const centerData = siteData?.data.map((site) => ({
    value: site.id.toString(),
    label: site.name,
  }));

  const excelDownLoad = async () => {
    let colWidths;
    let headerRow;
    let dataRows;
    let rows;

    const response = await TicketUserViewInstance.ticketUserViewFindPost({
      query: [
        JSON.stringify({
          $and: [
            {
              incomingAt: {
                $or: [
                  {
                    $between: getStartAndEndOfDateRange(
                      selectedDateRange[0],
                      selectedDateRange[1]
                    ),
                  },
                ],
              },
            },
            ...queryData,
          ],
        }),
      ],
      populate: [
        "serviceCenterInfo",
        "assignedTechnician",
        "assignedAdviser",
        "site",
        "dealer",
      ],
      sort: "-id",
    });

    const data = response.data;

    // 열의 폭을 정의
    colWidths = [
      80, 136, 240, 128, 112, 80, 160, 128, 96, 144, 160, 192, 176, 176, 176,
      192, 176, 192, 176, 80, 136, 168, 112, 136, 136, 136, 136, 120, 120, 120,
    ];

    headerRow = [
      { v: "회사코드", t: "s", s: headerStyle },
      { v: "서비스 센터 소속", t: "s", s: headerStyle },
      { v: "서비스 센터명", t: "s", s: headerStyle },
      { v: "담당 어드바이저", t: "s", s: headerStyle },
      { v: "담당 테크니션", t: "s", s: headerStyle },
      { v: "차량번호", t: "s", s: headerStyle },
      { v: "차량모델", t: "s", s: headerStyle },
      { v: "VIN", t: "s", s: headerStyle },
      { v: "고객명", t: "s", s: headerStyle },
      { v: "전화번호", t: "s", s: headerStyle },
      { v: "비고", t: "s", s: headerStyle },
      { v: "입고시간", t: "s", s: headerStyle },
      { v: "정비 전 지연시간", t: "s", s: headerStyle },
      { v: "정비 중 지연시간", t: "s", s: headerStyle },
      { v: "출고 전 지연시간", t: "s", s: headerStyle },
      { v: "정비 완료시간", t: "s", s: headerStyle },
      { v: "정비 소요시간", t: "s", s: headerStyle },
      { v: "마감시간", t: "s", s: headerStyle },
      { v: "정비 마감소요시간", t: "s", s: headerStyle },
      { v: "방문횟수", t: "s", s: headerStyle },
      { v: "알림톡 사용 여부", t: "s", s: headerStyle },
      { v: "알림톡 고객 열람 여부", t: "s", s: headerStyle },
      { v: "지연발생 횟수", t: "s", s: headerStyle },
      { v: "정비 전 지연 발생", t: "s", s: headerStyle },
      { v: "정비 전 지연 알림", t: "s", s: headerStyle },
      { v: "정비 중 지연 발생", t: "s", s: headerStyle },
      { v: "정비 중 지연 알림", t: "s", s: headerStyle },
      { v: "정비재개 알림", t: "s", s: headerStyle },
      { v: "출고 지연 발생", t: "s", s: headerStyle },
      { v: "출고 지연 알림", t: "s", s: headerStyle },
    ];

    dataRows =
      (data &&
        data.map((ticket: TicketsGet200ResponseRowsInner) => [
          { v: ticket.dealer?.dealerHqCode ?? "", t: "s", s: dataStyle },
          { v: ticket.dealer?.dealerHq ?? "", t: "s", s: dataStyle },
          { v: ticket.site?.name ?? "", t: "s", s: dataStyle },
          { v: ticket.assignedAdviser?.name ?? "", t: "s", s: dataStyle },
          { v: ticket.assignedTechnician?.name ?? "", t: "s", s: dataStyle },
          { v: ticket.plateCode ?? "", t: "s", s: dataStyle },
          { v: ticket.carModel ?? "", t: "s", s: dataStyle },
          { v: ticket.vinCode ?? "", t: "s", s: dataStyle },
          { v: ticket.customerName ?? "", t: "s", s: dataStyle },
          { v: ticket.mobile, t: "s", s: dataStyle },
          { v: ticket.description ?? "", t: "s", s: dataStyle },
          {
            v: ticket?.incomingAt
              ? (dayjs(ticket.incomingAt).valueOf() + 9 * 60 * 60 * 1000) /
                  (24 * 60 * 60 * 1000) +
                25569
              : "",
            t: "n",
            s: {
              ...dataStyle,
              numFmt: "yyyy-mm-dd [$-ko-KR]AM/PM hh:mm", // 한국 표준시 오후/오전 표기
            },
          },
          // { v: ticket?.incomingAt ? (dayjs(ticket?.incomingAt).isValid() ? dayjs(ticket?.incomingAt).format("YYYY-MM-DD A hh:mm") : "Invalid Date") : "", t: 's', s: dataStyle },  // 직급
          {
            v: (ticket.serviceCenterInfo?.acceptPendingTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          {
            v: (ticket.serviceCenterInfo?.stagePendingTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          {
            v: (ticket.serviceCenterInfo?.deliveryPendingTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          {
            v: ticket.serviceCenterInfo?.confirmTime
              ? (dayjs(ticket.serviceCenterInfo?.confirmTime).valueOf() +
                  9 * 60 * 60 * 1000) /
                  (24 * 60 * 60 * 1000) +
                25569
              : "",
            t: "n",
            s: {
              ...dataStyle,
              numFmt: "yyyy-mm-dd [$-ko-KR]AM/PM hh:mm", // 한국 표준시 오후/오전 표기
            },
          },

          // { v: ticket.serviceCenterInfo?.confirmTime ? (dayjs(ticket?.serviceCenterInfo?.confirmTime).isValid() ? dayjs(ticket?.serviceCenterInfo?.confirmTime).format("YYYY-MM-DD A hh:mm") : "Invalid Date") : "", t: 's', s: dataStyle },
          {
            v: (ticket.serviceCenterInfo?.confirmDurationTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          // { v: ticket.serviceCenterInfo?.confirmDurationTime ?? "", t: 's', s: dataStyle },
          {
            v: ticket.serviceCenterInfo?.closedTime
              ? (dayjs(ticket.serviceCenterInfo?.closedTime).valueOf() +
                  9 * 60 * 60 * 1000) /
                  (24 * 60 * 60 * 1000) +
                25569
              : "",
            t: "n",
            s: {
              ...dataStyle,
              numFmt: "yyyy-mm-dd [$-ko-KR]AM/PM hh:mm", // 한국 표준시 오후/오전 표기
            },
          },
          // { v: ticket.serviceCenterInfo?.closedTime ? (dayjs(ticket?.serviceCenterInfo?.closedTime).isValid() ? dayjs(ticket?.serviceCenterInfo?.closedTime).format("YYYY-MM-DD A hh:mm") : "Invalid Date") : "", t: 's', s: dataStyle },
          {
            v: (ticket.serviceCenterInfo?.closedDurationTime ?? 0) / 86400, // 초 단위 데이터를 엑셀 날짜 형식으로 변환
            t: "n", // 숫자 형식으로 저장
            s: {
              ...dataStyle,
              numFmt: "d일 h시간 mm분 ss초", // 일, 시간, 분, 초 형식으로 표시
            },
          },
          // { v: ticket.serviceCenterInfo?.closedDurationTime ?? "", t: 's', s: dataStyle },
          { v: "-", t: "s", s: dataStyle }, // 방문 횟수
          {
            v: ticket.usesNotification ? "사용" : "미사용",
            t: "s",
            s: dataStyle,
          },
          { v: "-", t: "s", s: dataStyle }, // 알림톡 고객 열람 여부
          {
            v: ticket.serviceCenterInfo?.totalPendingCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.acceptPendingCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.acceptPendingAlarmCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.stagePendingCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.stagePendingAlarmCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.resumeAlarmCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.deliveryPendingCount,
            t: "s",
            s: dataStyle,
          },
          {
            v: ticket.serviceCenterInfo?.deliveryPendingAlarmCount,
            t: "s",
            s: dataStyle,
          },
        ])) ??
      [];

    rows = [headerRow, ...dataRows];

    // Excel 파일 생성 및 다운로드
    const wb = XLSX.utils.book_new();

    // cols 속성을 사용하여 각 열의 폭을 조절
    const cols = colWidths?.map((width) => ({ wpx: width })) ?? [];
    // 새로운 Sheet 객체 생성
    const ws = XLSX.utils.aoa_to_sheet(rows);

    // cols 속성 적용
    ws["!cols"] = cols;

    // workbook에 추가
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // 파일 다운로드
    XLSX.writeFile(
      wb,
      `${level}_${dayjs(selectedDateRange[0]).format("YYYY-MM-DD")}~${dayjs(
        selectedDateRange[1]
      ).format("YYYY-MM-DD")}.xlsx`
    );

    return;
  };

  return (
    <Flex direction="column" gap="xs" p="lg">
      <Title>데이터 관리</Title>
      <Title order={2}>검색조건 설정</Title>
      <DataManagement.Box>
        <Flex direction="column" gap="xs" w="100%">
          <Flex w="100%" justify="flex-start" gap="xl">
            <PopoverDatePicker
              date={{ selectedDateRange, setSelectedDateRange }}
            />
            <MultiSelect
              searchable
              w="15rem"
              rightSection={<IconSearch />}
              placeholder="딜러사명"
              label={
                <Flex align="center">
                  <IconBuilding />
                  <Text fz="lg" fw={700}>
                    딜러사
                  </Text>
                </Flex>
              }
              data={dealerData || []}
              value={dealers}
              onChange={setDealers}
              disabled={
                userInfo?.siteId !== 99 &&
                (userInfo?.role === ADMIN || userInfo?.role === DEALER)
              }
            />
            <MultiSelect
              searchable
              w="15rem"
              rightSection={<IconSearch />}
              placeholder="서비스센터명"
              label={
                <Flex align="center">
                  <IconBuilding />
                  <Text fz="lg" fw={700}>
                    서비스센터
                  </Text>
                </Flex>
              }
              data={centerData || []}
              value={centers}
              onChange={setCenters}
              disabled={userInfo?.siteId !== 99 && userInfo?.role === ADMIN}
            />
          </Flex>

          <Divider />
          <Flex justify="flex-end" gap="sm">
            <Button
              color="indigo"
              leftIcon={<IconRefresh />}
              onClick={() => {
                setInitialize();
              }}
            >
              초기화
            </Button>
            <Button
              leftIcon={<IconSearch />}
              onClick={() => {
                setCenterPage(1);
                setSearchLoading(true);
              }}
            >
              조회
            </Button>
          </Flex>
        </Flex>
      </DataManagement.Box>

      <Title order={2}>조회 결과</Title>
      <DataManagement.Box>
        <Flex direction="column" gap="md" w="100%">
          <Flex justify="flex-end">
            <Button
              disabled={
                !(level.length > 0) && !(ticketData && ticketData.length > 0)
              }
              color="green"
              leftIcon={<IconCloudDownload />}
              onClick={() => executeWithLoading(excelDownLoad)}
            >
              엑셀추출
            </Button>
          </Flex>
          {(searchLoading || isLoading) && <Loading />}
          {level === "SERVICE" && ticketData && (
            <>
              <Flex
                direction="column"
                align="flex-start"
                bg="white"
                w="100%"
                style={{ overflowY: "auto" }}
              >
                <Table withColumnBorders withBorder>
                  <thead>
                    <tr>
                      <Th minWidth={5}>
                        <Text fz="lg">회사코드</Text>
                      </Th>
                      <Th minWidth={8.5}>
                        <Text fz="lg">서비스 센터 소속</Text>
                      </Th>
                      {/* <Th><Text fz="lg">지역</Text></Th> */}
                      <Th minWidth={15}>
                        <Text fz="lg">서비스 센터명</Text>
                      </Th>
                      <Th minWidth={8}>
                        <Text fz="lg">담당 어드바이저</Text>
                      </Th>
                      <Th minWidth={7}>
                        <Text fz="lg">담당 테크니션</Text>
                      </Th>
                      <Th minWidth={5}>
                        <Text fz="lg">차량번호</Text>
                      </Th>
                      <Th minWidth={10}>
                        <Text fz="lg">차량모델</Text>
                      </Th>
                      <Th minWidth={8}>
                        <Text fz="lg">VIN</Text>
                      </Th>
                      <Th minWidth={6}>
                        <Text fz="lg">고객명</Text>
                      </Th>
                      <Th minWidth={9}>
                        <Text fz="lg">전화번호</Text>
                      </Th>
                      <Th minWidth={10}>
                        <Text fz="lg">비고</Text>
                      </Th>
                      <Th minWidth={12}>
                        <Text fz="lg">입고시간</Text>
                      </Th>
                      <Th minWidth={11}>
                        <Text fz="lg">정비 전 지연시간</Text>
                      </Th>
                      <Th minWidth={11}>
                        <Text fz="lg">정비 중 지연시간</Text>
                      </Th>
                      <Th minWidth={11}>
                        <Text fz="lg">출고 전 지연시간</Text>
                      </Th>
                      <Th minWidth={12}>
                        <Text fz="lg">정비 완료시간</Text>
                      </Th>
                      <Th minWidth={11}>
                        <Text fz="lg">정비 소요시간</Text>
                      </Th>
                      <Th minWidth={12}>
                        <Text fz="lg">마감시간</Text>
                      </Th>
                      <Th minWidth={11}>
                        <Text fz="lg">정비 마감소요시간</Text>
                      </Th>
                      <Th minWidth={5}>
                        <Text fz="lg">방문횟수</Text>
                      </Th>
                      <Th minWidth={8.5}>
                        <Text fz="lg">알림톡 사용 여부</Text>
                      </Th>
                      <Th minWidth={10.5}>
                        <Text fz="lg">알림톡 고객 열람 여부</Text>
                      </Th>
                      <Th minWidth={7}>
                        <Text fz="lg">지연발생 횟수</Text>
                      </Th>
                      <Th minWidth={8.5}>
                        <Text fz="lg">정비 전 지연 발생</Text>
                      </Th>
                      <Th minWidth={8.5}>
                        <Text fz="lg">정비 전 지연 알림</Text>
                      </Th>
                      <Th minWidth={8.5}>
                        <Text fz="lg">정비 중 지연 발생</Text>
                      </Th>
                      <Th minWidth={8.5}>
                        <Text fz="lg">정비 중 지연 알림</Text>
                      </Th>
                      <Th minWidth={7.5}>
                        <Text fz="lg">정비재개 알림</Text>
                      </Th>
                      <Th minWidth={7.5}>
                        <Text fz="lg">출고 지연 발생</Text>
                      </Th>
                      <Th minWidth={7.5}>
                        <Text fz="lg">출고 지연 알림</Text>
                      </Th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketData.length > 0 ? (
                      ticketData?.map(
                        (
                          ticket: TicketsGet200ResponseRowsInner,
                          index: number
                        ) => (
                          <tr key={ticket.id}>
                            <td>
                              <Text fz="lg">{ticket.dealer?.dealerHqCode}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.dealer?.dealerHq}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.site?.name}</Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {ticket.assignedAdviser?.name}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {ticket.assignedTechnician?.name}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.plateCode}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.carModel}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.vinCode}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.customerName}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.mobile}</Text>
                            </td>
                            <td>
                              <Text fz="lg">{ticket.description}</Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {ticket?.incomingAt
                                  ? dayjs(ticket?.incomingAt).isValid()
                                    ? dayjs(ticket?.incomingAt).format(
                                        "YYYY-MM-DD A hh:mm"
                                      )
                                    : "Invalid Date"
                                  : ""}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {convertFormatDuration(
                                  ticket.serviceCenterInfo?.acceptPendingTime ??
                                    0
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {convertFormatDuration(
                                  ticket.serviceCenterInfo?.stagePendingTime ??
                                    0
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {convertFormatDuration(
                                  ticket.serviceCenterInfo
                                    ?.deliveryPendingTime ?? 0
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {ticket.serviceCenterInfo?.confirmTime
                                  ? dayjs(
                                      ticket.serviceCenterInfo?.confirmTime
                                    ).isValid()
                                    ? dayjs(
                                        ticket.serviceCenterInfo?.confirmTime
                                      ).format("YYYY-MM-DD A hh:mm")
                                    : "Invalid Date"
                                  : ""}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {convertFormatDuration(
                                  ticket.serviceCenterInfo
                                    ?.confirmDurationTime ?? 0
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {ticket.serviceCenterInfo?.closedTime
                                  ? dayjs(
                                      ticket.serviceCenterInfo?.closedTime
                                    ).isValid()
                                    ? dayjs(
                                        ticket.serviceCenterInfo?.closedTime
                                      ).format("YYYY-MM-DD A hh:mm")
                                    : "Invalid Date"
                                  : ""}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">
                                {convertFormatDuration(
                                  ticket.serviceCenterInfo
                                    ?.closedDurationTime ?? 0
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg">{/* 방문 횟수 */}-</Text>
                            </td>
                            <td>
                              <Flex h="100%" justify="center" align="center">
                                <Badge
                                  size="lg"
                                  color={ticket?.usesNotification ? "" : "red"}
                                >
                                  {ticket.usesNotification ? "사용" : "미사용"}
                                </Badge>
                              </Flex>
                            </td>
                            <td>
                              <Text fz="lg">
                                {/* 알림톡 고객 열람 여부 */}-
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {ticket.serviceCenterInfo?.totalPendingCount}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {ticket.serviceCenterInfo?.acceptPendingCount}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {
                                  ticket.serviceCenterInfo
                                    ?.acceptPendingAlarmCount
                                }
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {ticket.serviceCenterInfo?.stagePendingCount}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {
                                  ticket.serviceCenterInfo
                                    ?.stagePendingAlarmCount
                                }
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {ticket.serviceCenterInfo?.resumeAlarmCount}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {ticket.serviceCenterInfo?.deliveryPendingCount}
                              </Text>
                            </td>
                            <td>
                              <Text fz="lg" ta="end">
                                {
                                  ticket.serviceCenterInfo
                                    ?.deliveryPendingAlarmCount
                                }
                              </Text>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={30}>
                          <Text fz="lg">데이터가 존재하지 않습니다.</Text>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Flex>
              <Pagination
                value={centerPage}
                total={ticketCenterData?.data?.totalPages ?? (0 as number)}
                onChange={(value) => {
                  setCenterPage(value);
                  setSearchLoading(true);
                }}
                position="center"
              />
            </>
          )}
        </Flex>
      </DataManagement.Box>
    </Flex>
  );
};

export const Th = styled.th<{ minWidth?: number; width?: number }>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;
