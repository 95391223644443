import styled from "@emotion/styled";
import { AppShell } from "@mantine/core";
import { AuthSignupPost201ResponseRoleEnum } from "@sizlcorp/mbk-api-document/dist/models";
import NavbarTop from "components/Layout/NavBarTop";
import { SideBar } from "components/Layout/SideBar";
import { MobileFooter } from "components/Mobile/Layout/Footer";
import { MobileHeader } from "components/Mobile/Layout/Header";
import ProtectedRoute from "components/Router/ProtectedRoute";
import { useInitializeUserInfo } from "hook/useInitializeUserInfo";
import { useResponsiveMode } from "hook/useResponsiveMode";
import Auth from "pages/auth";
import Dashboard from "pages/dashboard";
import { DataManagementPage } from "pages/dataManagement";
import { DataManagementCenterPage } from "pages/dataManagement/Center";
import { DataManagementDealerPage } from "pages/dataManagement/Dealer";
import InformationPage from "pages/informationManagement";
import PendingReasonPage from "pages/pendingReasons/PendingReasons";
import ProcessTypePage from "pages/processTypes";
import Reception from "pages/receptions";
import ReceptionArchive from "pages/receptionsArchive";
import SiteConfigPage from "pages/siteconfig";
import Users from "pages/user";
import { Outlet, Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";
import { useCheckAuth } from "utils/checkAuth";

function Index() {
  const auth = useCheckAuth();

  // ✅ 사용자 정보 초기화
  useInitializeUserInfo();

  // ✅ Recoil에서 role, siteId 확인
  const userInfo = useRecoilValue(userInfoAtom);
  const role = userInfo?.role;
  const isAdmin = userInfo?.siteId === 99;

  return (
    <Routes>
      <Route element={<Layout auth={auth} />}>
        {/* 로그인되지 않은 경우 Auth 페이지로 이동 */}
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/dashboard">
              <Auth />
            </ProtectedRoute>
          }
        />

        {/* MBK, DEALER 권한은 제외하고 나머지 권한만 Dashboard 페이지 접근 가능 */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
              ]}
              role={role}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* Reception 페이지는 TECHNICIAN, ADVISER, ADMIN만 접근 가능 */}
        <Route
          path="/reception"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
              ]}
              role={role}
            >
              <Reception />
            </ProtectedRoute>
          }
        />

        {/* Reception Archive 페이지는 TECHNICIAN, ADVISER, ADMIN만 접근 가능 */}
        <Route
          path="/receptionArchive"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
              ]}
              role={role}
            >
              <ReceptionArchive />
            </ProtectedRoute>
          }
        />

        {/* Users 페이지는 ADMIN만 접근 가능 */}
        <Route
          path="/users"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.ADMIN]}
              role={role}
            >
              <Users />
            </ProtectedRoute>
          }
        />

        {/* Information Management 페이지는 다양한 권한이 접근 가능 */}
        <Route
          path="/informationManagement"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.TECHNICIAN,
                AuthSignupPost201ResponseRoleEnum.ADVISER,
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <InformationPage />
            </ProtectedRoute>
          }
        />

        {/* Site Config 페이지는 ADMIN, DEALER, MBK만 접근 가능 */}
        <Route
          path="/siteconfig"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <SiteConfigPage />
            </ProtectedRoute>
          }
        />

        {/* Pending Reason 페이지는 ADMIN만 접근 가능 */}
        <Route
          path="pendingReason"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.ADMIN]}
              role={role}
            >
              <PendingReasonPage />
            </ProtectedRoute>
          }
        />

        {/* Process Type 페이지는 ADMIN만 접근 가능 */}
        <Route
          path="processType"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.ADMIN]}
              role={role}
            >
              <ProcessTypePage />
            </ProtectedRoute>
          }
        />

        {/* Data Management 페이지는 ADMIN, DEALER, MBK만 접근 가능 */}
        <Route
          path="dataManagement"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <DataManagementPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="dataManagement-dealer"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
              isAdmin={isAdmin} // siteId === 99인지 확인 후 전달
            >
              <DataManagementDealerPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="dataManagement-center"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[
                AuthSignupPost201ResponseRoleEnum.ADMIN,
                AuthSignupPost201ResponseRoleEnum.DEALER,
                AuthSignupPost201ResponseRoleEnum.MBK,
              ]}
              role={role}
            >
              <DataManagementCenterPage />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="notice"
          element={
            <ProtectedRoute
              redirectCondition={!auth}
              redirectPath="/"
              allowedRoles={[AuthSignupPost201ResponseRoleEnum.MBK]}
              role={role}
              isAdmin={isAdmin} // siteId === 99인지 확인 후 전달
            >
              <NoticePage />
            </ProtectedRoute>
          }
        /> */}
      </Route>
    </Routes>
  );
}

const Layout = ({ auth }: { auth: boolean }) => {
  const {
    isMobileMode: isMobile,
    isTabletMode: isTablet,
    isDesktopMode: isDesktop,
  } = useResponsiveMode();

  return (
    <div style={{ height: "100vh", background: "#000" }}>
      {(isMobile || isTablet) && (
        <AppShell
          bg="white"
          padding={0}
          header={auth ? <MobileHeader /> : <></>}
          footer={auth ? <MobileFooter /> : <></>}
        >
          <Outlet />
        </AppShell>
      )}
      {/* {isTablet && <Outlet />} */}
      {isDesktop &&
        (!auth ? (
          <Outlet />
        ) : (
          <>
            <Wrapper>
              <SideBar />
              <Container>
                <NavbarTop />
                <Outlet />
              </Container>
            </Wrapper>
          </>
        ))}
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  width: calc(100% - 210px);
  height: 100%;
  margin-left: 210px;
  background: #f5f6fb;

  @media (max-width: 1199px) {
    & {
      width: 100%;
      margin-left: 0;
    }
  }
`;

export default Index;
