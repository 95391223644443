import { authEnum } from "config/auth";
import { useResetRecoilState } from "recoil";
import { userInfoAtom } from "recoil/authAtom";

export const useLogout = () => {
  // const navigate = useNavigate();
  const resetUserInfo = useResetRecoilState(userInfoAtom);

  return function logout() {
    // 1. Recoil 상태 초기화
    resetUserInfo();

    // 2. localStorage 항목 제거
    localStorage.removeItem(authEnum.authToken);
    localStorage.removeItem("siteId");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");

    // 3. 로그인 페이지로 이동
    // navigate("/");
  };
};
