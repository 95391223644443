import { Flex, Footer, Text } from "@mantine/core";
import {
  IconClipboardCheckFilled,
  IconClipboardTextFilled,
  IconHomeFilled,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";

export const MobileFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isReception = location.pathname === "/reception";
  const isDashboard = location.pathname === "/dashboard";
  const isReceptionArchive = location.pathname === "/receptionArchive";

  return (
    <Footer w="100%" height="3.5rem">
      <Flex
        justify="space-between"
        align="center"
        gap="xl"
        px="4rem"
        py="0.4375rem"
      >
        <Flex
          direction="column"
          justify="center"
          align="center"
          gap="0.25rem"
          onClick={() => {
            navigate("/reception");
          }}
        >
          <IconClipboardTextFilled color={isReception ? "" : "#ADB5BD"} />
          <Text
            size="0.6875rem"
            style={{ lineHeight: "0.875rem" }}
            c={isReception ? "" : "dimmed"}
          >
            접수현황
          </Text>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          gap="0.25rem"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <IconHomeFilled color={isDashboard ? "" : "#ADB5BD"} />
          {/* <IconHomeFilled /> */}
          <Text
            size="0.6875rem"
            style={{ lineHeight: "0.875rem" }}
            c={isDashboard ? "" : "dimmed"}
          >
            홈
          </Text>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          gap="0.25rem"
          onClick={() => {
            navigate("/receptionArchive");
          }}
        >
          <IconClipboardCheckFilled
            color={isReceptionArchive ? "" : "#ADB5BD"}
          />
          <Text
            size="0.6875rem"
            style={{ lineHeight: "0.875rem" }}
            c={isReceptionArchive ? "" : "dimmed"}
          >
            마감현황
          </Text>
        </Flex>
      </Flex>
    </Footer>
  );
};
