import { Global, css, useTheme } from "@emotion/react";
import { useEffect } from "react";

function GlobalStyle() {
  const theme = useTheme();

  useEffect(() => {
    const html = document.querySelector("html");
    html && (html.style.backgroundColor = theme.backgroundColor);
  }, [theme]);

  const globalCSS = css`
    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video,
    a,
    span {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: top;
      box-sizing: border-box;
      -ms-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -moz-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-text-size-adjust: none;
      -moz-text-size-adjust: none;
    }
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }

    img,
    video {
      max-width: 100%;
      vertical-align: middle;
    }
    a {
      text-decoration: none;
    }
    a:link,
    a:visited,
    a:hover,
    a:active,
    a:focus {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    input,
    select {
      vertical-align: middle;
    }
    button {
      cursor: pointer;
      margin: 0;
      padding: 0;
      border: none;
      background: none;
    }
    .blind,
    legend,
    hr {
      display: block;
      position: absolute;
      top: 0;
      left: -1000em;
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
    }

    .skip,
    .skip_navi {
      visibility: hidden;
      overflow: hidden;
      position: absolute;
      left: -999em;
      width: 0;
      height: 0;
      font-size: 0;
      line-height: 0;
    }

    /* input[type="text"],  */
    /* input[type="password"] {padding:2px 5px; min-height:40px; border:1px solid #babeca;} */
    input[type="checkbox"],
    input[type="radio"],
    label {
      cursor: pointer;
    }
    // input[type="radio"] {margin:0; vertical-align:middle; position:relative; bottom:1px; width:19px; height:19px; border:0 !important;}
    input[type="checkbox"] {
      background: #ffffff;
      margin: 0;
      vertical-align: middle;
      position: relative;
      bottom: 1px;
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      word-break: keep-all;
      -ms-word-break: keep-all;
      font-family: MBK CorpoS !important;
    }

    @font-face {
      font-family: "MBK CorpoS";
      src: url(../fonts/MBKCorporateS.eot);
      src: url(../fonts/MBKCorporateS.eot?#iefix) format("embedded-opentype"),
        url(../fonts/MBKCorporateS.woff) format("woff"),
        url(../fonts/MBKCorporateS.woff2) format("woff2"),
        url(../fonts/MBKCorporateS.ttf) format("truetype");
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    html {
      height: 100%;
      font-size: 100%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
    }
    body {
      font-size: 16px;
      height: 100%;
      color: #333;
      background: rgb(59, 71, 88);
      line-height: inherit;
    }

    .ac {
      text-align: center !important;
    }
    .al {
      text-align: left !important;
    }
    .ar {
      text-align: right !important;
    }

    .fr {
      float: right !important;
    }
    .fl {
      float: left !important;
    }
  `;

  return <Global styles={[globalCSS]} />;
}

export default GlobalStyle;
