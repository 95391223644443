// pages/ReceptionPage.tsx
import { useQueries } from "@tanstack/react-query";
import { ticketReports } from "api/ticketReport/useTicketReportQuery";
import { withResponsiveComponent } from "components/WithResponsiveComponent";
import { useState } from "react";
import {
  TicketsGet200ResponseRowsInner,
  TicketsMonthStatusPost200ResponseInner,
  TicketsTodayStatusPost200ResponseInner,
  TicketsWeekStatusPost200ResponseInner,
} from "@sizlcorp/mbk-api-document/dist/models";
import { Loading } from "components/Loading";
export interface DashboardProps {
  dashboardData: {
    isMyTicketOnly: boolean;
    setIsMyTicketOnly: (value: boolean) => void;
    maintenanceData: TicketsTodayStatusPost200ResponseInner[];
    urgentData: TicketsGet200ResponseRowsInner[];
    toCloseData: TicketsGet200ResponseRowsInner[];
    weeklyStats: TicketsWeekStatusPost200ResponseInner[];
    monthlyStats: TicketsMonthStatusPost200ResponseInner[];
  };
}

const DashboardPage = withResponsiveComponent({
  mobile: () => import("./Mobile"),
  desktop: () => import("./Desktop"),
});

const DashboardPageWrapper = () => {
  const [isMyTicketOnly, setIsMyTicketOnly] = useState<boolean>(false);
  const results = useQueries({
    queries: [
      ticketReports.postTodayStatus({ assignedTickets: isMyTicketOnly }),
      ticketReports.postUrgencyStatus({ assignedTickets: isMyTicketOnly }),
      ticketReports.postToCloseStatus({ assignedTickets: isMyTicketOnly }),
      ticketReports.postWeekStatus({ assignedTickets: isMyTicketOnly }),
      ticketReports.postMonthStatus({ assignedTickets: isMyTicketOnly }),
    ],
  });

  const [
    { data: maintenanceData, isLoading: isLoadingMaintenanceData }, // 정비단계별 현황
    { data: urgentData, isLoading: isLoadingUrgentData }, // 신속 조치 필요 항목
    { data: toCloseData, isLoading: isLoadingToCloseData }, // 마감 필요 항목
    { data: weeklyStats, isLoading: isLoadingWeeklyStats }, // 주간 접수 건수
    { data: monthlyStats, isLoading: isLoadingMonthlyStats }, // 월간 접수 건수
  ] = results;

  // 하나라도 로딩 중이면 전체 로딩 UI
  const isLoading =
    isLoadingMaintenanceData ||
    isLoadingUrgentData ||
    isLoadingToCloseData ||
    isLoadingWeeklyStats ||
    isLoadingMonthlyStats;

  if (isLoading) return <Loading />;

  return (
    <DashboardPage
      dashboardData={{
        isMyTicketOnly,
        setIsMyTicketOnly,
        maintenanceData: maintenanceData?.data || [],
        urgentData: urgentData?.data || [],
        toCloseData: toCloseData?.data || [],
        weeklyStats: weeklyStats?.data || [],
        monthlyStats: monthlyStats?.data || [],
      }}
    />
  );
};

export default DashboardPageWrapper;
