import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ModalStackManager from "components/Modal/Atom/context/ModalStackManager";
import ThemeProvider from "provider/ThemeProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import GlobalStyle from "style/Global";
import App from "./App";
import DrawerStackManager from "components/DrawerStackManager";
import { Notifications } from "@mantine/notifications";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider>
        <MantineProvider
          withGlobalStyles
          theme={{
            components: {
              Input: {
                styles: () => ({
                  input: {
                    fontSize: 14,
                    "@media (max-width: 768px)": {
                      fontSize: 16,
                    },
                  },
                }),
              },
              PasswordInput: {
                styles: () => ({
                  innerInput: {
                    fontSize: 14,
                    "@media (max-width: 768px)": {
                      fontSize: 16,
                    },
                  },
                }),
              },
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <ModalStackManager>
              <DrawerStackManager>
                <Notifications position="top-center" zIndex={9999} />
                <GlobalStyle />
                <App />
              </DrawerStackManager>
            </ModalStackManager>
          </QueryClientProvider>
        </MantineProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);
