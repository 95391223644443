import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201Response,
  MasterApiUsersUserIdPutRequest,
  UsersUserIdDeleteRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateUser } from "api/users/useUsersQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import { roles } from "constants/roles";
import { FormatterProps } from "react-data-grid";

const EditForm = ({
  formatterProps,
}: {
  formatterProps: FormatterProps<AuthSignupPost201Response, unknown>;
}) => {
  const formData = useForm<UsersUserIdDeleteRequest>({
    initialValues: {
      siteId: Number(localStorage.getItem("siteId")),
      email: formatterProps?.row.email,
      name: formatterProps?.row.name,
      role: formatterProps?.row.role,
      // password: formatterProps?.row.password || "",
      mobile: formatterProps?.row.mobile || "",
      exposedMobile: formatterProps?.row.exposedMobile || "",
    },
  });

  // const [passwordCheck, setPasswordCheck] = useState<string>(
  //   formatterProps?.row.password || ""
  // );

  const { closeModal } = useModal();

  const queryClient = useQueryClient();

  const { mutate: mutateUsersUserIdPut } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUser
        .update(params)
        .mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
      onError: (error: unknown) => {
        alert("중복된 사용자 아이디 입니다. 다른 아이디를 사용해 주세요.");
      },
    }
  );

  const onSubmit = async () => {
    if (!formData.values?.email || formData.values.email.trim().length === 0) {
      alert("사용자 아이디를 입력해주세요.");
      return;
    }
    // if (
    //   !(
    //     formData.values.email.length > 0 &&
    //     formData.values.name.length > 0 &&
    //     formData.values.password.length > 0
    //   ) ||
    //   formData.values.password !== passwordCheck
    // ) {
    //   alert("필수 항목들을 입력해주세요.");
    //   return;
    // }

    try {
      mutateUsersUserIdPut(
        {
          userId: formatterProps?.row.id,
          usersUserIdDeleteRequest: {
            email: formData.values.email,
            name: formData.values.name || "",
            role: formData.values.role,
            // password: formData.values.password,
            mobile: formData.values.mobile || "",
            exposedMobile: formData.values.exposedMobile || "",
          },
        },
        {
          onSuccess: () => {
            alert("사용자 정보가 수정되었습니다.");
            closeModal({});
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex direction="column" gap="md" w="20rem">
      <TextInput
        withAsterisk
        label="사용자아이디"
        placeholder="예: advisor"
        {...formData.getInputProps("email")}
      />
      <TextInput
        // withAsterisk
        label="이름"
        placeholder="예: 홍길동"
        {...formData.getInputProps("name")}
      />
      <TextInput
        // withAsterisk
        label="핸드폰 번호"
        placeholder="예: 010-9876-5432"
        {...formData.getInputProps("mobile")}
      />
      <TextInput
        // withAsterisk
        label="노출 가능한 핸드폰 번호"
        placeholder="예: 010-1234-5678"
        {...formData.getInputProps("exposedMobile")}
      />
      <Select
        // withAsterisk
        label="포지션"
        data={roles.filter((role) => !role.isManagement)}
        {...formData.getInputProps("role")}
      />
      {/* <TextInput
        withAsterisk
        label="패스워드"
        placeholder="**********"
        {...formData.getInputProps("password")}
        type="password"
      />
      <TextInput
        withAsterisk
        label="패스워드 확인"
        placeholder="**********"
        value={passwordCheck}
        onChange={(e) => setPasswordCheck(e.currentTarget.value)}
        type="password"
        error={
          formData.values.password !== passwordCheck
            ? "패스워드가 일치하지 않습니다."
            : ""
        }
      /> */}
      <Flex justify="flex-end" gap="md">
        <Button color="gray" onClick={() => closeModal({})}>
          취소
        </Button>
        <Button
          onClick={onSubmit}
          // disabled={
          //   !(
          //     formData.values.email.length > 0 &&
          //     formData.values.name.length > 0 &&
          //     formData.values.password.length > 0 &&
          //     (formData?.values?.mobile as string)?.length > 0 &&
          //     (formData?.values?.exposedMobile as string)?.length > 0
          //   ) || formData.values.password !== passwordCheck
          // }
        >
          사용자 정보 수정
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditForm;
