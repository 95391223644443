import styled from "@emotion/styled";
import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPostRequest,
  AuthSignupPostRequestRoleEnum,
  UserApiAuthSignupPostRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateUser } from "api/users/useUsersQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import { roles } from "constants/roles";
import { useState } from "react";
import { colors } from "style/theme";

const RegisterForm = () => {
  const formData = useForm<AuthSignupPostRequest>({
    initialValues: {
      siteId: Number(localStorage.getItem("siteId")),
      email: "",
      name: "",
      role: AuthSignupPostRequestRoleEnum.TECHNICIAN,
      password: "",
      exposedMobile: "",
    },
  });

  const [passwordCheck, setPasswordCheck] = useState<string>("");

  const { closeModal } = useModal();

  const queryClient = useQueryClient();

  const { mutate: mutateAuthSignupPost } = useMutation(
    (params: UserApiAuthSignupPostRequest) =>
      mutateUser
        .create(params)
        .mutationFn(params as UserApiAuthSignupPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        alert("사용자가 추가되었습니다.");
        closeModal({});
      },
      onError: (error: unknown) => {
        alert("중복된 사용자 아이디 입니다. 다른 아이디를 사용해 주세요.");
      },
    }
  );

  const onSubmit = async () => {
    if (
      !(
        formData.values.email.length > 0 &&
        formData.values.name.length > 0 &&
        formData.values.password.length > 0 &&
        (formData?.values?.mobile as string)?.length > 0 &&
        (formData?.values?.exposedMobile as string)?.length > 0
      ) ||
      formData.values.password !== passwordCheck
    ) {
      alert("필수 항목들을 입력해주세요.");
      return;
    }
    try {
      mutateAuthSignupPost({
        authSignupPostRequest: {
          siteId: Number(localStorage.getItem("siteId")),
          email: formData.values.email,
          name: formData.values.name,
          role: formData.values.role,
          password: formData.values.password,
          mobile: formData.values.mobile || undefined,
          exposedMobile: formData.values.exposedMobile || undefined,
        },
      });
    } catch (e: any) {
      console.log("error", e);
    }
  };

  return (
    <Flex direction="column" gap="md" w="20rem">
      <TextInput
        withAsterisk
        label="사용자아이디"
        placeholder="예: advisor"
        {...formData.getInputProps("email")}
      />
      <TextInput
        withAsterisk
        label="이름"
        placeholder="예: 홍길동"
        {...formData.getInputProps("name")}
      />
      <TextInput
        withAsterisk
        label="핸드폰 번호"
        placeholder="예: 010-9876-5432"
        {...formData.getInputProps("mobile")}
      />
      <TextInput
        withAsterisk
        label="노출 가능한 핸드폰 번호"
        placeholder="예: 010-1234-5678"
        {...formData.getInputProps("exposedMobile")}
      />
      <Select
        withAsterisk
        label="포지션"
        data={roles.filter((role) => !role.isManagement)}
        {...formData.getInputProps("role")}
      />
      <TextInput
        withAsterisk
        label="패스워드"
        placeholder="**********"
        {...formData.getInputProps("password")}
        type="password"
      />
      <TextInput
        withAsterisk
        label="패스워드 확인"
        value={passwordCheck}
        placeholder="**********"
        onChange={(e) => setPasswordCheck(e.currentTarget.value)}
        type="password"
        error={
          formData.values.password !== passwordCheck
            ? "패스워드가 일치하지 않습니다."
            : ""
        }
      />
      <Flex justify="flex-end" gap="md">
        <Button color="gray" onClick={() => closeModal({})}>
          취소
        </Button>
        <Button
          onClick={onSubmit}
          disabled={
            !(
              formData.values.email.length > 0 &&
              formData.values.name.length > 0 &&
              formData.values.password.length > 0 &&
              (formData?.values?.mobile as string)?.length > 0 &&
              (formData?.values?.exposedMobile as string)?.length > 0
            ) || formData.values.password !== passwordCheck
          }
        >
          사용자 추가
        </Button>
      </Flex>
    </Flex>
  );
};

export default RegisterForm;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;

  & button {
    width: 54px;
    height: 40px;
    background-color: ${colors.pointColorBlue};
    color: white;
    border-radius: 6px;
  }
`;
