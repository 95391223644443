import { createContext } from "react";

import BaseBox from "components/Box/BaseBox";
import TestBaseButton from "components/Button/Atom/TestBaseButton";
import BaseContents from "components/Contents/BaseContents";
import InformationManagement from "./Information";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";
export const InformationCTX = createContext<any>(null);

const Information = Object.assign({}, "", {
  Content: BaseContents,
  Box: BaseBox,
  Button: TestBaseButton,
});

function InformationPage() {
  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <Information.Content title="내 정보 관리">
      <Information.Box>
        <InformationManagement userId={userInfo?.id!} />
      </Information.Box>
    </Information.Content>
  );
}

export default InformationPage;
