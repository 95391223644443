import { Button, Flex, Pagination, Select } from "@mantine/core";
import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";

import { AuthSignupPost201Response } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import SearchBox, { SearchProps } from "components/SearchBox";
import { pageOptions } from "constants/pageOptions";
import { usersSearchType } from "constants/searchType";
import { useMemo, useState } from "react";
import RegisterForm from "./RegisterForm";
import UsersTable, { SortProps } from "./UsersTable";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";

const Users = Object.assign({}, "", {
  Content: BaseContents,
  Box: BaseBox,
  SearchBox: SearchBox,
});

const filteredSearchType = usersSearchType
  .filter((type) => type.value)
  .map((type) => type.value);

const positionOptions = [
  { label: "전체", value: "ALL" },
  { label: "관리자", value: "ADMIN" },
  { label: "어드바이저", value: "ADVISER" },
  { label: "테크니션", value: "TECHNICIAN" },
  { label: "딜러사", value: "DEALER" },
  { label: "MBK", value: "MBK" },
] as const;

type PositionValue = (typeof positionOptions)[number]["value"];

function UsersPage() {
  const userInfo = useRecoilValue(userInfoAtom);
  const siteId = userInfo?.siteId;

  const isSuperAdmin = siteId === 99;

  // ✅ 포지션 필터 상태
  const [positionFilter, setPositionFilter] = useState<PositionValue>("ALL");

  // ✅ baseQuery 동적 설정
  const baseQuery = useMemo(() => {
    const query: Record<string, any> = {};

    if (!isSuperAdmin && siteId !== undefined) {
      query.siteId = { $eq: siteId };
    }

    const allRoles = ["ADMIN", "ADVISER", "TECHNICIAN", "DEALER", "MBK"];
    const defaultRolesForUser = ["ADMIN", "ADVISER", "TECHNICIAN"];

    if (positionFilter === "ALL") {
      query.role = {
        $in: isSuperAdmin ? allRoles : defaultRolesForUser,
      };
    } else if (!isSuperAdmin && ["DEALER", "MBK"].includes(positionFilter)) {
      // 일반 유저가 제한된 포지션을 선택한 경우 → 전체 필터로 대체
      query.role = { $in: defaultRolesForUser };
    } else {
      query.role = { $eq: positionFilter };
    }

    return query;
  }, [isSuperAdmin, siteId, positionFilter]);

  const { openModal } = useModal();
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
  const [search, setSearch] = useState<SearchProps>({
    search: "",
    searchFields: [],
    pageSize: "10",
  });
  const [sortColumns, setSortColumns] = useState<Map<string, SortProps>>(
    (new Map() as Map<string, SortProps>).set("createdAt", {
      columnKey: "createdAt",
      direction: undefined,
    })
  );

  const [page, setPage] = useState<number>(1);

  const { data } = useQuery({
    ...users.get({
      query: [JSON.stringify({ $and: baseQuery })],
      page: page,
      pageSize: perPageRows,
      populate: ["site"],
      search: search.search,
      searchFields: search.searchFields.length
        ? search.searchFields
        : filteredSearchType,
      sort: "-id",
    }),
    enabled: siteId !== undefined,
  });

  return (
    <Users.Content
      title={
        <Flex justify={"space-between"}>
          {"사용자 관리"}
          <Button
            color="blue"
            onClick={() =>
              openModal(<RegisterForm />, "", "사용자 정보 등록", true)
            }
          >
            {"신규 등록"}
          </Button>
        </Flex>
      }
    >
      {/* ✅ UI 필터 버튼 */}
      <Flex gap={5} mb={10} justify="flex-end">
        {positionOptions
          .filter(({ value }) =>
            // 슈퍼관리자가 아니면 DEALER, MBK 제외
            isSuperAdmin ? true : !["DEALER", "MBK"].includes(value)
          )
          .map(({ label, value }) => (
            <Button
              key={value}
              w="8rem"
              bg={positionFilter === value ? "blue" : "rgb(211, 212, 214)"}
              c="white"
              onClick={() => {
                setPositionFilter(value);
                setPage(1);
              }}
            >
              {label}
            </Button>
          ))}
      </Flex>

      <Users.Box>
        <Flex gap={5} justify={"space-between"} mb={20}>
          <Select
            placeholder="페이지당 개수"
            data={pageOptions}
            onChange={(e) => {
              setPage(1);
              setPerPageRows(Number(e));
            }}
          ></Select>
          <SearchBox
            searchType={usersSearchType}
            setSearch={(searchedValue: any) => {
              setSearch(searchedValue);
              setPage(1);
            }}
          />
        </Flex>
        <UsersTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          sortColumns={sortColumns}
          setSortColumns={setSortColumns}
          users={data?.data?.rows as AuthSignupPost201Response[]}
        />
        <Flex justify={"center"} mt={20}>
          <Pagination
            value={page}
            total={data?.data?.totalPages as number}
            onChange={(value) => setPage(value)}
          />
        </Flex>
      </Users.Box>
    </Users.Content>
  );
}

export default UsersPage;
