import styled from "@emotion/styled";
import { authEnum } from "config/auth";
import { ReactComponent as LightLogo } from "images/svg/Light_logo.svg";
import { colors } from "style/theme";
import errorBoundary from "utils/errorBoundary";
// import axios from "instance/axios";
import { PasswordInput, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import BaseInstance, { UserInstance } from "instance/axios";
import { useNavigate } from "react-router-dom";
import decodeToken from "utils/decodeToken";
import { handleKeyDown } from "utils/event";
import { useRef } from "react";
import { userInfoAtom } from "recoil/authAtom";
import { useSetRecoilState } from "recoil";
import { useQueryClient } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";

function Auth() {
  const form = useForm({
    initialValues: {
      id: "",
      password: "",
    },
  });

  const passwordRef = useRef<HTMLInputElement>(null);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  // const setRecoilAuthToken = useSetRecoilState(authTokenAtom);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const getInfo = async (token: string) => {
    const { data } = await BaseInstance.whoamiGet({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 1. Recoil 저장
    setUserInfo({
      id: data.id,
      name: data.name,
      email: data.email,
      siteId: data.siteId,
      role: data.role,
      site: data.site,
    });

    // 2. 캐싱
    queryClient.setQueryData(users.who({}).queryKey, { data });

    // 3. 이동
    navigate("/dashboard");
  };

  const authFetcher = async () => {
    try {
      const response = await UserInstance.authLoginPost(
        {
          authLoginPostRequest: {
            identifier: form.values.id,
            password: form.values.password,
          },
        },
        {
          headers: {
            Authorization: undefined,
          },
        }
      );
      const token = response.data?.token;

      localStorage.setItem(authEnum.authToken, token);
      const userInfo = decodeToken(token);
      localStorage.setItem("siteId", userInfo.user.siteId);
      localStorage.setItem("userId", userInfo.user.id);
      await getInfo(token);
      // setRecoilAuthToken(token);
    } catch (error: any) {
      alert(error?.response?.data?.message);
      passwordRef.current?.focus();
    }
  };

  const onSignIn = async () => {
    const idValue = form.values.id;
    const passwordValue = form.values.password;
    const validate = idValue && passwordValue;

    if (validate) {
      await errorBoundary(authFetcher);
    } else {
      alert("please enter id, password");
    }
  };

  return (
    <Container>
      <LogoBox>
        <LightLogo />
      </LogoBox>

      <SignInSection>
        <AuthenticationTitle>Sign In</AuthenticationTitle>

        <AuthForm>
          <TextInput
            label={<Text color="white">ID</Text>}
            placeholder="please enter your ID"
            onKeyDown={(e) => handleKeyDown(e, onSignIn)}
            {...form.getInputProps("id")}
          />
          <PasswordInput
            label={<Text color="white">Password</Text>}
            onKeyDown={(e) => handleKeyDown(e, onSignIn)}
            {...form.getInputProps("password")}
            ref={passwordRef}
          />
        </AuthForm>

        <OptionBox>
          {/* <CheckAndRadio>
            <BaseCheckbox notifier={(ischecked) => setIsRemember(ischecked)} />
            <CheckboxText>Remember me</CheckboxText>
          </CheckAndRadio> */}

          {/* <ForgotPass>Forgot password?</ForgotPass> */}
        </OptionBox>

        <ButtonBox>
          <Button onClick={onSignIn}>Sign in</Button>
        </ButtonBox>
      </SignInSection>

      <Ellipse />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${colors.pointColorGray};
`;

const LogoBox = styled.div`
  position: absolute;
  top: 38px;
  left: 36px;
  z-index: 99;
`;

const Ellipse = styled.div`
  width: 3000px;
  height: 1200px;
  background: ${colors.indigo};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 70%;
  border: 1px black solid;
  position: absolute;
  transform: translateY(-75%);
`;

const SignInSection = styled.section`
  width: 432px;
  height: fit-content;
  background-color: ${colors.indigo};
  z-index: var(--zIndex-1st);
  color: ${colors.white};

  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 20px rgba(6.82, 6.02, 18.06, 0.1);
  border-radius: 12px;

  transform: translateY(40px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1000;
`;

const AuthenticationTitle = styled.h2`
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;
`;

const AuthForm = styled.div`
  width: 100%;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const OptionBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

const ButtonBox = styled.div`
  width: 100%;
  height: 48px;
  margin-top: 40px;
`;
const Button = styled.button`
  width: 100%;
  height: 100%;

  background-color: #0077e4;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
`;

const SignUpBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 3px;
  padding: 12px 0;
  justify-content: center;
`;
const SignUpText = styled.p`
  color: #dde1e8;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;
const SignUpAnchor = styled.a`
  color: #319dff;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export default Auth;
