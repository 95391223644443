import { AuthSignupPost201ResponseRoleEnum } from "@sizlcorp/mbk-api-document/dist/models";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  redirectCondition: boolean;
  redirectPath: string;
  role?: AuthSignupPost201ResponseRoleEnum;
  allowedRoles?: AuthSignupPost201ResponseRoleEnum[];
  children: JSX.Element;
  isAdmin?: boolean;
}

const ProtectedRoute = ({
  redirectCondition,
  redirectPath,
  role,
  allowedRoles,
  children,
  isAdmin = false, // 기본값 설정
}: ProtectedRouteProps) => {
  // 로그인되지 않은 경우 리다이렉션
  if (redirectCondition) {
    return <Navigate to={redirectPath} />;
  }

  // isAdmin이 true라면 권한 체크 없이 접근 허용
  if (isAdmin) {
    return children;
  }

  // 특정 페이지에 허용된 권한이 없는 경우
  if (allowedRoles && role && !allowedRoles.includes(role)) {
    // MBK 또는 DEALER인 경우 dataManagement로 리다이렉션
    if (
      role === AuthSignupPost201ResponseRoleEnum.MBK ||
      role === AuthSignupPost201ResponseRoleEnum.DEALER
    ) {
      return <Navigate to="/dataManagement-dealer" />;
    }

    // 그 외의 경우에는 기본적으로 다른 페이지로 리다이렉션
    return <Navigate to="/" />;
  }

  return children;
};
export default ProtectedRoute;
