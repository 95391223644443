import { Button, Flex, PasswordInput, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mutateUser, users } from "api/users/useUsersQuery";
import { useDrawer } from "components/DrawerStackManager";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfoAtom } from "recoil/authAtom";
import { roles } from "constants/roles";
import BaseInstance, { UserInstance } from "instance/axios";
import decodeToken from "utils/decodeToken";
import { authEnum } from "config/auth";
import {
  AuthSignupPostRequestRoleEnum,
  MasterApiUsersUserIdPutRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { notifications } from "@mantine/notifications";

export const MyPageForm = () => {
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const userInfo = useRecoilValue(userInfoAtom);

  const queryClient = useQueryClient();
  const { mutate: userIdPut } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUser
        .update(params)
        .mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    {
      onSuccess: () => {},
    }
  );

  const { data: userData } = useQuery({
    ...users.detail({ userId: userInfo?.id! }),
    enabled: !!userInfo?.id,
    select: (data) => data.data,
  });

  const { closeDrawer } = useDrawer();
  const form = useForm({
    initialValues: {
      siteId: Number(localStorage.getItem("siteId")),
      email: userData?.email || "",
      password: "",
      name: userData?.name || "",
      mobile: userData?.mobile || "",
      exposedMobile: userData?.exposedMobile || "",
      role: userData?.role || "",
    },
  });

  const [passwordCheck, setPasswordCheck] = useState<string>("");

  useEffect(() => {
    if (userData) {
      form.setValues({
        siteId: Number(localStorage.getItem("siteId")),
        email: userData?.email || "",
        password: "",
        name: userData?.name || "",
        mobile: userData?.mobile || "",
        exposedMobile: userData?.exposedMobile || "",
        role: userData?.role || "",
      });
    }
  }, [userData]);

  const getInfo = async (token: string) => {
    const { data } = await BaseInstance.whoamiGet({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 1. Recoil 저장
    setUserInfo({
      id: data.id,
      name: data.name,
      email: data.email,
      siteId: data.siteId,
      role: data.role,
      site: data.site,
    });

    // 2. 캐싱
    queryClient.setQueryData(users.who({}).queryKey, { data });
  };

  const validation = () => {
    if (!form.values.email || form.values.email.trim().length === 0) {
      notifications.show({
        title: "아이디 입력 확인",
        message: "사용자 아이디를 입력해주세요.",
        color: "red",
      });
      return false;
    }
    if (form.values.password !== passwordCheck) {
      notifications.show({
        title: "패스워드 확인",
        message: "패스워드와 패스워드 확인이 일치하지 않습니다.",
        color: "red",
      });
      return false;
    } else if (form.values.email !== userData?.email && !form.values.password) {
      notifications.show({
        title: "정보 입력 확인",
        message: "아이디와 패스워드를 입력해주세요.",
        color: "red",
      });
      return false;
    } else if (!form.values.password || !passwordCheck) {
      notifications.show({
        title: "패스워드 입력 확인",
        message: "패스워드와 패스워드 확인을 입력해주세요.",
        color: "red",
      });
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = async () => {
    if (validation()) {
      try {
        userIdPut(
          {
            userId: userData?.id as number,
            usersUserIdDeleteRequest: {
              name: form.values.name as string,
              email: form.values.email as string,
              password: form.values.password as string,
              mobile: form.values.mobile,
              exposedMobile: form.values.exposedMobile,
              role: form.values.role as AuthSignupPostRequestRoleEnum,
              siteId: localStorage.getItem("siteId")
                ? Number(localStorage.getItem("siteId"))
                : 1,
            },
          },
          {
            onSuccess: async () => {
              const identifier =
                form.values.email !== undefined
                  ? form.values.email
                  : userData?.email;

              try {
                localStorage.removeItem(authEnum.authToken);
                localStorage.removeItem("siteId");
                localStorage.removeItem("role");

                const response = await UserInstance.authLoginPost(
                  {
                    authLoginPostRequest: {
                      identifier: identifier as string,
                      password: form.values.password,
                    },
                  },
                  {
                    headers: {
                      Authorization: undefined,
                    },
                  }
                );

                const token = response.data?.token;
                localStorage.setItem(authEnum.authToken, token);
                const userInfo = decodeToken(token);
                localStorage.setItem("siteId", userInfo.user.siteId);
                localStorage.setItem("userId", userInfo.user.id);

                await getInfo(token);

                queryClient.invalidateQueries(["users"]);

                notifications.show({
                  title: "내 정보 수정 완료",
                  message: "사용자 정보가 수정되었습니다.",
                  color: "blue",
                });
                closeDrawer();
              } catch (error: any) {
                alert(error?.response?.data?.message);
              }
            },
            onError: (error: unknown) => {
              notifications.show({
                title: "중복된 아이디",
                message:
                  "중복된 사용자 아이디 입니다. 다른 아이디를 사용해 주세요.",
                color: "red",
              });
            },
          }
        );
      } catch (error) {
        alert("사용자 정보 수정이 실패하였습니다. 다시 시도해주세요.");
      }
    }
  };

  return (
    <Flex w="100%" direction="column" justify="space-between" h="100%">
      <Flex direction="column" gap="xs" px="lg">
        <TextInput label="사용자 아이디" {...form.getInputProps("email")} />
        <PasswordInput label="패스워드" {...form.getInputProps("password")} />
        <PasswordInput
          label="패스워드 확인"
          value={passwordCheck}
          onChange={(e) => setPasswordCheck(e.target.value)}
        />
        <TextInput label="이름" {...form.getInputProps("name")} />
        <TextInput label="전화번호" {...form.getInputProps("mobile")} />
        <TextInput
          label="노출 가능한 전화번호"
          {...form.getInputProps("exposedMobile")}
        />
        <Select
          data={roles}
          label="포지션"
          {...form.getInputProps("role")}
          disabled
          value={userData?.role}
        />
      </Flex>
      <Flex px="xl" pt="0.5rem" pb="sm" gap="0.6875rem">
        <Button
          variant="light"
          color="gray"
          c="black"
          w="100%"
          h="3rem"
          fz="1.125rem"
          fw={400}
          size="lg"
          radius="sm"
          onClick={() => {
            closeDrawer();
          }}
        >
          취소
        </Button>
        <Button
          w="100%"
          h="3rem"
          fz="1.125rem"
          fw={400}
          size="lg"
          onClick={onSubmit}
        >
          수정
        </Button>
      </Flex>
    </Flex>
  );
};
