import {
  AuthSignupPost201ResponseRoleEnum,
  AuthSignupPost201ResponseSite,
} from "@sizlcorp/mbk-api-document/dist/models";
import { atom } from "recoil";

export const authTokenAtom = atom({
  key: "authTokenAtom",
  default: "",
});

export interface UserInfo {
  id: number;
  name: string;
  email: string;
  siteId: number;
  site?: AuthSignupPost201ResponseSite;
  role: AuthSignupPost201ResponseRoleEnum;
}

export const userInfoAtom = atom<UserInfo | null>({
  key: "userInfoAtom",
  default: null,
});
