import { Flex, Text } from "@mantine/core";
import { IconLogout, IconUser } from "@tabler/icons-react";
import { useDrawer } from "components/DrawerStackManager";
import { MyPageForm } from "components/Mobile/MyPage/Form";
import { useLogout } from "hook/useLogout";

export const MyPageDrawer = () => {
  const { openDrawer, closeDrawer } = useDrawer();
  const logout = useLogout();
  return (
    <Flex w="100%" direction="column" h="100%">
      <Flex
        px="md"
        py="0.6875rem"
        gap="0.5rem"
        align="center"
        onClick={() => {
          openDrawer(
            <MyPageForm />,
            null,
            <Text w="100%" fz="xl" fw={600}>
              내 정보 관리
            </Text>,
            false,
            "100%"
          );
        }}
      >
        <IconUser />
        <Text size="lg">내 정보 관리</Text>
      </Flex>
      <Flex
        px="md"
        py="0.6875rem"
        gap="0.5rem"
        align="center"
        onClick={() => {
          closeDrawer();
          logout();
        }}
      >
        <IconLogout />
        <Text size="lg">로그아웃</Text>
      </Flex>
    </Flex>
  );
};
