import { Button, Flex, SystemProp, Text } from "@mantine/core";
import React, { CSSProperties } from "react";

interface ConfirmProps {
  message: string | JSX.Element;
  yesMessage?: string;
  noMessage?: string;
  yesCallback: () => void;
  noCallback: () => void;
  commonCallback?: () => void;
  minWidth?: SystemProp<CSSProperties["minWidth"]>;
}

export const Confirm: React.FC<ConfirmProps> = ({
  message,
  yesMessage = "예",
  noMessage = "아니오",
  yesCallback,
  noCallback,
  commonCallback = () => {},
  minWidth,
}) => {
  const callback = async (callbackFn: () => void) => {
    await callbackFn();
    await commonCallback();
  };

  return (
    <Flex
      miw={minWidth ?? "20rem"}
      direction="column"
      gap="sm"
      justify="space-between"
    >
      <Text
        style={{
          wordWrap: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {message}
      </Text>
      <Flex gap="md" justify="flex-end">
        <Button color="gray" onClick={() => callback(noCallback)}>
          {noMessage}
        </Button>
        <Button onClick={() => callback(yesCallback)}>{yesMessage}</Button>
      </Flex>
    </Flex>
  );
};
