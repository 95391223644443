import { Button, Flex, Header, Text } from "@mantine/core";
import { IconChevronDown, IconUserFilled } from "@tabler/icons-react";
import { ReactComponent as MobileBenzLogo } from "assets/benz_mobile_logo.svg";
import { useDrawer } from "components/DrawerStackManager";
import { MyPageDrawer } from "components/Mobile/DrawerMenu/MyPageDrawer";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";

export const MobileHeader = () => {
  const userInfo = useRecoilValue(userInfoAtom);
  const { openDrawer } = useDrawer();
  const navigate = useNavigate();

  return (
    <Header w="100%" p="md" height={68}>
      <Flex justify="space-between" align="center">
        <MobileBenzLogo
          onClick={() => {
            navigate("/dashboard");
          }}
        />
        <Button
          variant="light"
          compact
          color="dark"
          bg="gray.1"
          onClick={() => {
            openDrawer(
              <MyPageDrawer />,
              null,
              <Text w="100%" ta="center" fz="1.125rem" fw={600}>
                마이페이지
              </Text>,
              false,
              "25%"
            );
          }}
        >
          <IconUserFilled size={20} color="dark" />
          <Text fz={13} fw={500}>
            {userInfo?.name}님
          </Text>
          <IconChevronDown size={14} />
        </Button>
      </Flex>
    </Header>
  );
};
