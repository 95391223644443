// hook/useInitializeUserInfo.ts
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/authAtom";

export const useInitializeUserInfo = () => {
  const authToken = localStorage.getItem("authToken");
  const userInfo = useRecoilValue(userInfoAtom);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const queryClient = useQueryClient();

  const shouldFetch = Boolean(authToken) && userInfo === null;

  useQuery({
    ...users.who({}),
    enabled: shouldFetch,
    onSuccess: (response) => {
      const user = response.data;

      // 1. Recoil 상태 업데이트
      setUserInfo({
        id: user.id,
        name: user.name,
        email: user.email,
        siteId: user.siteId,
        site: user.site ?? undefined,
        role: user.role,
      });

      // 2. Query 캐싱
      queryClient.setQueryData(users.who({}).queryKey, response);
    },
  });
};
